import { FC, useEffect } from 'react'
import { appInsights } from '../api/AppInsights'
import { getCurrentUser, postRegisterUser } from '../api/UserService'
import { useAuth0 } from '@auth0/auth0-react'
import Cookies from 'universal-cookie'
import { add } from 'date-fns'
import { useSetRecoilState } from 'recoil'
import { CurrentUser } from '../stores/AppStore'
import { ResponseData } from '../models/api/IResponse'

const UserSetup: FC = () => {
  const { user, isAuthenticated } = useAuth0()
  if (appInsights) appInsights.trackPageView({ name: 'Register' })

  const setCurrentUser = useSetRecoilState(CurrentUser)

  useEffect(() => {
    const cookies = new Cookies()
    const loadCurrentUser = async () => {
      const user = ResponseData(await getCurrentUser({}))
      setCurrentUser(user)
    }
    const registerUser = async () => {
      if (isAuthenticated) {
        const response = await postRegisterUser({
          userId: user?.sub as string,
          name: user?.name as string,
          email: user?.email as string,
        })
        cookies.set(
          'atc.user',
          { registered: response?.success },
          { path: '/', expires: add(new Date(), { years: 1 }) },
        )
      }
    }

    // Skip the api call if the user is already registered
    const userCookie = cookies.get('atc.user')
    if (!userCookie?.registered) {
      registerUser()
    }
    loadCurrentUser()
  }, [user, isAuthenticated, setCurrentUser])

  return null
}

export default UserSetup
