import { getAsync, postAsync } from '../helpers/apiRequest'
import { IUser } from '../models/IUser'
import { IBodyRequestModel } from '../models/api/IBodyRequestModel'
import { IResponse } from '../models/api/IResponse'
import {
  IRegisterAuthUserRequest,
  IRegisterAuthUserResponse,
  IRegisterUserRequest,
  IRegisterUserResponse,
} from '../models/api/IUserRequestModel'
import { TokenExpiryWrapper } from './TokenManager'

export const postRegisterUser = TokenExpiryWrapper(
  async (request: IRegisterUserRequest): Promise<IResponse<IRegisterUserResponse>> => postAsync(`/v1/user`, request),
  [],
  null,
)

export const searchUsers = TokenExpiryWrapper(
  (request: IRegisterAuthUserRequest): Promise<IResponse<IRegisterAuthUserResponse[]>> => {
    return getAsync(`/v1/user/search/${request.email}`, request)
  },
  [],
  null,
)

export const getCurrentUser = TokenExpiryWrapper(
  (request: IBodyRequestModel): Promise<IResponse<IUser>> => {
    return getAsync(`/v1/user/current`, request)
  },
  [],
  null,
)
