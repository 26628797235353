import { Button } from '@aurecon-creative-technologies/styleguide'
import { FC, useCallback } from 'react'
import Style from '../styles/SearchCard.module.sass'
import { useMediaQuery } from 'react-responsive'
import { SCRIPT_LIBRARY_VIEW_URL, UI_WIDTH_COLLAPSE } from '../config/config'
import { AutomationUploadTypes } from '../enums/AutomationUploadTypes'
import { TagTypes } from '../enums/TagTypes'
import { renderTags } from './AutomationCard'
import { IGetAutomationResponse } from '../models/api/IAutomationRequest'
import { dateToString } from '../helpers/utils'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../enums/AppRoutes'
import { handleDownload } from '../helpers/commonFunctions'
import { ITagProps } from './Tag'
import Likes from './Likes'

export interface ISearchAutomationCardProps {
  automation: IGetAutomationResponse
}

const SearchAutomationCard: FC<ISearchAutomationCardProps> = (props) => {
  const { automation } = props
  const isDesktop = useMediaQuery({ minWidth: UI_WIDTH_COLLAPSE })
  const capabilityTags: ITagProps[] =
    automation.AutomationCapabilities?.map((capability) => ({
      id: capability.Id.toString(),
      type: TagTypes.CAPABILITY,
      label: capability.Name,
    })) ?? []

  const sourceTags: ITagProps[] =
    automation.AutomationSources?.map((source) => ({
      id: source.Id.toString(),
      type: TagTypes.SOURCE,
      label: source.Name,
    })) ?? []
  const navigate = useNavigate()

  const handleViewClick = () => {
    if (automation.UploadTypeId === AutomationUploadTypes.SCRIPT_LIBRARY) {
      window.open(`${SCRIPT_LIBRARY_VIEW_URL}/${automation.Id}`, '_blank')
      return
    }

    if (automation.AutomationUrl?.Url) {
      window.open(automation.AutomationUrl.Url, '_blank')
    }
  }

  const handleDownloadClick = () => {
    handleDownload(automation)
  }

  const handleCardLink = useCallback(() => {
    if (automation.UploadTypeId === AutomationUploadTypes.SCRIPT_LIBRARY) {
      navigate(`/${AppRoutes.VIEW_AUTOMATION}/${automation.Id}/${AutomationUploadTypes.SCRIPT_LIBRARY}`)
      return
    }

    if (automation.UploadTypeId === AutomationUploadTypes.STREAMLINER) {
      navigate(`/${AppRoutes.VIEW_AUTOMATION}/${automation.Id}/${AutomationUploadTypes.STREAMLINER}`)
      return
    }

    navigate(`/${AppRoutes.VIEW_AUTOMATION}/${automation.Id}`)
  }, [automation.Id, automation.UploadTypeId, navigate])

  return (
    <div className={Style.searchCard} onClick={handleCardLink} role='none'>
      <div className={Style.buttonSection}>
        {automation.UploadTypeId === AutomationUploadTypes.UPLOAD_FILE ? (
          <Button
            type='icon-round'
            size='extra small'
            icon='download'
            cssClass={Style.cardButtons}
            onClick={(e) => {
              e.stopPropagation()
              handleDownloadClick()
            }}
          />
        ) : (
          <Button
            type='icon-round'
            size='extra small'
            icon='open_in_new'
            cssClass={Style.cardButtons}
            onClick={(e) => {
              e.stopPropagation()
              handleViewClick()
            }}
          />
        )}
      </div>

      <div className={Style.automationTitle}>{automation.Title}</div>
      <div className={Style.metadata}>
        <Likes id={automation.Id} />
        <div className={Style.creationDate}>
          {automation.DateCreated ? dateToString(automation.DateCreated ?? '') : 'N/A'}
        </div>
      </div>
      <div className={Style.description}>{automation.Description}</div>

      <div>
        {renderTags(capabilityTags, isDesktop, true)}
        {renderTags(sourceTags, isDesktop, true)}
      </div>
    </div>
  )
}

export default SearchAutomationCard
