import { FC, useRef, useState } from 'react'
import Style from '../styles/SearchComboBox.module.sass'
import { ComboBox, Grid, IOption, Pill, Tooltip } from '@aurecon-creative-technologies/styleguide'
import classNames from 'classnames'
import debounce from 'debounce'
interface ISearchComboBox {
  selectedItems: ISearchComboBoxProps[]
  label: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  error?: string
  tooltipMessage?: string
  items?: IOption[]
  isLoading?: boolean
  onSearchTextChange: (value: string) => void
  onSelectedItemsChange: (data: IOption[]) => void
}

export interface ISearchComboBoxProps extends IOption {
  name?: string
}

const SearchComboBox: FC<ISearchComboBox> = (props) => {
  const {
    label,
    placeholder,
    required,
    disabled,
    selectedItems,
    error,
    items,
    isLoading,
    tooltipMessage,
    onSearchTextChange,
    onSelectedItemsChange,
  } = props
  const clientRef = useRef<HTMLInputElement | null>(null)
  const [val, setVal] = useState('')

  const handleRemoveItem = (key: string) => {
    const updatedItems = selectedItems.filter((x) => x.id !== key)
    onSelectedItemsChange(updatedItems)
  }

  const handleAddClient = (newOption: ISearchComboBoxProps | null) => {
    if (!newOption || !clientRef.current) return

    selectedItems.push(newOption)
    onSelectedItemsChange(selectedItems)
    clientRef.current.value = ''
    setVal('')
    clientRef.current.blur()
  }

  const onSearch = debounce(async (event: React.FormEvent<HTMLDivElement>) => {
    if (!clientRef.current) return
    const value = (event.target as HTMLInputElement).value
    setVal(value)
    onSearchTextChange(value)
  }, 500)

  const itemPillClasses = classNames({
    [Style.metadataPill]: true,
    [Style.item]: true,
  })

  const comboBoxClasses = classNames({
    [Style.comboBox]: true,
    [Style.loading]: isLoading,
  })

  const showPills = () => {
    return selectedItems.map((item) => (
      <Pill key={item.id} size='medium' cssClass={itemPillClasses} onClose={() => handleRemoveItem(item.id.toString())}>
        {item.value}
      </Pill>
    ))
  }

  return (
    <Grid item xs={12} cssClass={Style.metadataRow}>
      <Grid item xs={12} cssClass={Style.metadataFieldRow}>
        {tooltipMessage ? (
          <Tooltip padding={32} cssClass='tooltip-theme-dark' defaultUp show={tooltipMessage}>
            <div className={Style.metadataFieldlabel}>{label}</div>
          </Tooltip>
        ) : (
          <div className={Style.metadataFieldlabel}>{label}</div>
        )}
      </Grid>
      {selectedItems.length > 0 && (
        <Grid item xs={12} cssClass={Style.metadataFieldRow}>
          <div className={Style.metadataPillsBox}>{showPills()}</div>
        </Grid>
      )}
      <Grid item xs={12} cssClass={Style.metadataFieldRow}>
        <div className={comboBoxClasses} onChange={onSearch}>
          <ComboBox
            options={items || []}
            onSelect={handleAddClient}
            required={required}
            selected={val}
            disabled={disabled}
            placeholder={placeholder}
            ref={clientRef}
            icon={isLoading ? 'sync' : undefined}
          />
        </div>
        {error && <p className={Style.errorMessage}>{error}</p>}
      </Grid>
    </Grid>
  )
}

export default SearchComboBox
