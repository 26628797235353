import { atom, selector } from 'recoil'
import { getRecommendedAutomations, getScriptLibraryScripts } from '../api/ScriptLibrary'
import { ResponseData } from '../models/api/IResponse'
import { IGetScriptsResponse } from '../models/api/IScriptLibaryModel'
import { ScriptLibraryOrder } from '../enums/ScriptLibrary'
import { IGetAutomationResponse } from '../models/api/IAutomationRequest'

export const SLGetScriptsOrder = atom<string>({
  key: 'SLGetScriptsOrder',
  default: ScriptLibraryOrder.MOST_RECENT,
})

export const SLGetScriptsPage = atom<number>({
  key: 'SLGetScriptsPage',
  default: 1,
})

export const SLScripts = selector({
  key: 'script_library_loader',
  get: async ({ get }): Promise<IGetScriptsResponse> => {
    const order = get(SLGetScriptsOrder)
    const page = get(SLGetScriptsPage)
    try {
      const results = await getScriptLibraryScripts({ order, page })
      return ResponseData(results) as IGetScriptsResponse
    } catch (error) {
      console.log(error)
      return {
        items: [],
        totalCount: 0,
        pageSize: 1,
      }
    }
  },
})

export const RecommendedAutomations = selector({
  key: 'recommended_automations_loader',
  get: async ({ get }): Promise<IGetAutomationResponse[]> => {
    const order = get(SLGetScriptsOrder)
    try {
      const results = await getRecommendedAutomations({ order })
      const automations = ResponseData(results) as IGetAutomationResponse[]
      return automations
    } catch (error) {
      console.log(error)
      return []
    }
  },
})
