import { FC } from 'react'
import { Grid, Modal } from '@aurecon-creative-technologies/styleguide'
import classNames from 'classnames'

import LoadingScreen from './LoadingScreen'

import Style from '../styles/LoadingScreen.module.sass'

interface LoadingModalProps {
  title?: string
  message?: string
  cssClass?: string
}

const LoadingModal: FC<LoadingModalProps> = (props) => {
  const modalClasses = classNames({
    [Style.loadingModal]: true,
    [`${props.cssClass}`]: props.cssClass,
  })

  return (
    <Modal
      isShowing={true}
      shouldOverlayClose={false}
      isCloseButton={false}
      isOverlay
      modalPadding={{ top: '0', right: '24px', left: '24px', bottom: '24px' }}
      cssClass={modalClasses}
      onClose={() => null}
    >
      <Grid row gap={10} cssClass={Style.loadingModalContainer}>
        <Grid item xs={12}>
          {props.title && <h2>{props.title}</h2>}
          <LoadingScreen text={props.message} />
        </Grid>
      </Grid>
    </Modal>
  )
}

export default LoadingModal
