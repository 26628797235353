import { Loader } from '@aurecon-creative-technologies/styleguide'
import { FC, useEffect, useState } from 'react'
import DefaultFeaturedImg from '../assets/featured_card1.png'
import { ITagProps } from './Tag'
import { useMediaQuery } from 'react-responsive'
import { BLOB_THUMBNAILS_CONTAINER, UI_WIDTH_COLLAPSE } from '../config/config'
import { renderTags } from './AutomationCard'
import { getAutomationAssets } from '../api/StorageService'
import Likes from './Likes'

import Style from '../styles/FeaturedCard.module.sass'

export interface IFeaturedCardProps {
  title: string
  id: string
  creationDate: string
  description: string
  tags: ITagProps[]
  onClick?: () => void
  thumbnail?: string | null
}

const FeaturedCard: FC<IFeaturedCardProps> = (props) => {
  const { title, id, creationDate, description, tags, thumbnail, onClick } = props
  const [thumbnailUrl, setThumbnailUrl] = useState<string>()
  const [isThumbnailLoading, setIsThumbnailLoading] = useState<boolean>(false)
  const isDesktop = useMediaQuery({ minWidth: UI_WIDTH_COLLAPSE })

  useEffect(() => {
    const getThumbnail = async () => {
      if (!thumbnail) setThumbnailUrl(DefaultFeaturedImg)
      if (thumbnail) {
        setIsThumbnailLoading(true)
        const url = await getAutomationAssets({
          container: BLOB_THUMBNAILS_CONTAINER,
          blobName: thumbnail,
        })

        if (url) setThumbnailUrl(url)
        else setThumbnailUrl(DefaultFeaturedImg)

        setIsThumbnailLoading(false)
      }
    }

    getThumbnail()
  }, [thumbnail])

  return (
    <div className={Style.featuredCard} onClick={onClick} role='none'>
      <div className={Style.details}>
        <div className={Style.featuredTag}>Featured</div>
        <div className={Style.title}>{title}</div>
        <div className={Style.metadata}>
          <Likes id={id} />
          <div className={Style.creationDate}>{creationDate}</div>
        </div>
        <div className={Style.description}>{description}</div>

        <div className={Style.tags}>{renderTags(tags, isDesktop)}</div>
      </div>

      <div className={Style.thumbnail}>
        {isThumbnailLoading ? <Loader cssClass={Style.loader} size={'small'} /> : <img alt='img' src={thumbnailUrl} />}
      </div>
    </div>
  )
}

export default FeaturedCard
