import { Button, Dropdown, IDropdownItemProps, Pill } from '@aurecon-creative-technologies/styleguide'
import { FC, useEffect, useState } from 'react'
import { AutomationSources } from '../enums/AutomationSources'
import { Practises } from '../enums/Practises'
import { Regions } from '../enums/Regions'
import Style from '../styles/AutomationFilter.module.sass'
import { RecoilState, useRecoilState, useRecoilValueLoadable, useResetRecoilState } from 'recoil'
import classNames from 'classnames'
import { TagTypes } from '../enums/TagTypes'
import Tag from './Tag'
import { IFilterState } from '../stores/ManageAutomationStore'
import { AllCapabilities } from '../stores/CapabilityStore'

interface AutomationFilterProps {
  cssClass?: string
  filterState: RecoilState<IFilterState>
}

const AutomationFilter: FC<AutomationFilterProps> = (props) => {
  const [showPills, setShowPills] = useState<boolean>(true)
  const sources: IDropdownItemProps[] = AutomationSources.map((x) => ({ id: x.key, label: x.value }))
  const practises: IDropdownItemProps[] = Practises.map((x) => ({ id: x.key, label: x.value }))
  const regions: IDropdownItemProps[] = Regions.map((x) => ({ id: x.key, label: x.value }))

  const [automationFilters, setAutomationFilters] = useRecoilState(props.filterState)
  const [capabilities, setCapabilities] = useState<IDropdownItemProps[]>([])
  const resetFilters = useResetRecoilState(props.filterState)
  const capabilitiesLoader = useRecoilValueLoadable(AllCapabilities)

  useEffect(() => {
    if (capabilitiesLoader.state !== 'hasValue' || !capabilitiesLoader.contents) return
    const data = capabilitiesLoader.contents.map((x) => ({ id: x.Id.toString(), label: x.Name }))
    setCapabilities(data)
  }, [capabilitiesLoader.contents, capabilitiesLoader.state])

  function filterPill(list: IDropdownItemProps[], name: string) {
    return (
      <p>
        {name}
        {list.length != 0 && (
          <Pill cssClass={Style.pill} background='dark' size='mini'>
            {list.length}
          </Pill>
        )}
      </p>
    )
  }

  const accordionClass = classNames({
    [Style.accordionToggle]: true,
    [Style.active]: !showPills,
  })

  const containerClasses = classNames({
    [Style.automationFilters]: true,
    [`${props.cssClass}`]: props.cssClass,
  })

  const handleRemovePill = (list: IDropdownItemProps[], source: TagTypes, key: string) => {
    const updatedFilter = list.filter((x) => x.id !== key)
    setAutomationFilters({
      ...automationFilters,
      [source]: updatedFilter,
    })
  }

  return (
    <div className={containerClasses}>
      <div className={Style.filterContainer}>
        <p className={Style.filterText}> Filter </p>
        <div className={Style.filterDropdownsContainer}>
          <div className={Style.filterDropdown}>
            <Dropdown
              placeholder='All Sources'
              clearSelectionItem='- Clear selection -'
              items={sources}
              selectedItem={' '}
              onSelectMultipleItems={(selectedItemIds: (string | number)[]) => {
                const selectedItems = sources.filter((item) => selectedItemIds.includes(item.id))
                setAutomationFilters({
                  ...automationFilters,
                  [TagTypes.SOURCE]: selectedItems,
                })
              }}
              selectedMultipleItems={automationFilters.source.map((item) => item.id)}
              selectedMultipleItemsRender={() => filterPill(automationFilters.source, 'Source')}
              multipleDisplayValues
              multiple
              required
            />
          </div>
          <div className={Style.filterDropdown}>
            <Dropdown
              clearSelectionItem='- Clear selection -'
              items={capabilities}
              selectedItem={' '}
              onSelectMultipleItems={(selectedItemIds: (string | number)[]) => {
                const selectedItems = capabilities.filter((item) => selectedItemIds.includes(item.id))
                setAutomationFilters({
                  ...automationFilters,
                  [TagTypes.CAPABILITY]: selectedItems,
                })
              }}
              selectedMultipleItems={automationFilters.capability.map((item) => item.id)}
              selectedMultipleItemsRender={() => filterPill(automationFilters.capability, 'Capability')}
              placeholder={capabilities.length === 0 ? 'Loading...' : 'All Capabilities'}
              multipleDisplayValues
              disabled={capabilities.length === 0}
              multiple
              required
            />
          </div>
          <div className={Style.filterDropdown}>
            <Dropdown
              placeholder='All Practices'
              clearSelectionItem='- Clear selection -'
              items={practises}
              selectedItem={' '}
              onSelectMultipleItems={(selectedItemIds: (string | number)[]) => {
                const selectedItems = practises.filter((item) => selectedItemIds.includes(item.id))
                setAutomationFilters({
                  ...automationFilters,
                  [TagTypes.PRACTICE]: selectedItems,
                })
              }}
              selectedMultipleItems={automationFilters.practice.map((item) => item.id)}
              selectedMultipleItemsRender={() => filterPill(automationFilters.practice, 'Practice')}
              multipleDisplayValues
              multiple
              required
            />
          </div>
          <div className={Style.filterDropdown}>
            <Dropdown
              placeholder='All Regions'
              clearSelectionItem='- Clear selection -'
              items={regions}
              selectedItem={' '}
              onSelectMultipleItems={(selectedItemIds: (string | number)[]) => {
                const selectedItems = regions.filter((item) => selectedItemIds.includes(item.id))
                setAutomationFilters({
                  ...automationFilters,
                  [TagTypes.REGION]: selectedItems,
                })
              }}
              selectedMultipleItems={automationFilters.region.map((item) => item.id)}
              selectedMultipleItemsRender={() => filterPill(automationFilters.region, 'Region')}
              multipleDisplayValues
              multiple
              required
            />
          </div>
        </div>
        <div className={Style.controls}>
          <div onClick={resetFilters} className={Style.clearFilter} role='none'>
            Clear Filter
          </div>
          <Button
            type='icon-round'
            size='medium'
            icon={showPills ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            cssClass={accordionClass}
            onClick={() => setShowPills(!showPills)}
          />
        </div>
      </div>
      {showPills && (
        <div className={Style.pillsContainer}>
          {Boolean(automationFilters.source.length) && (
            <div className={Style.pills}>
              <div className={Style.title}>Source</div>
              <div className={Style.tags}>
                {automationFilters.source?.map((source) => (
                  <Tag
                    id={source.id.toString()}
                    type={TagTypes.SOURCE}
                    label={source.label as string}
                    key={source.id}
                    close={() => handleRemovePill(automationFilters.source, TagTypes.SOURCE, source.id.toString())}
                  />
                ))}
              </div>
            </div>
          )}

          {Boolean(automationFilters.capability.length) && (
            <div className={Style.pills}>
              <div className={Style.title}>Capability</div>
              <div className={Style.tags}>
                {automationFilters.capability?.map((capability) => (
                  <Tag
                    id={capability.id.toString()}
                    type={TagTypes.CAPABILITY}
                    label={capability.label as string}
                    key={capability.id}
                    close={() =>
                      handleRemovePill(automationFilters.capability, TagTypes.CAPABILITY, capability.id.toString())
                    }
                  />
                ))}
              </div>
            </div>
          )}

          {Boolean(automationFilters.practice.length) && (
            <div className={Style.pills}>
              <div className={Style.title}>Practice</div>
              <div className={Style.tags}>
                {automationFilters.practice?.map((practice) => (
                  <Tag
                    id={practice.id.toString()}
                    type={TagTypes.PRACTICE}
                    label={practice.label as string}
                    key={practice.id}
                    close={() =>
                      handleRemovePill(automationFilters.practice, TagTypes.PRACTICE, practice.id.toString())
                    }
                  />
                ))}
              </div>
            </div>
          )}

          {Boolean(automationFilters.region.length) && (
            <div className={Style.pills}>
              <div className={Style.title}>Region</div>
              <div className={Style.tags}>
                {automationFilters.region?.map((region) => (
                  <Tag
                    id={region.id.toString()}
                    type={TagTypes.REGION}
                    label={region.label as string}
                    key={region.id}
                    close={() => handleRemovePill(automationFilters.region, TagTypes.REGION, region.id.toString())}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default AutomationFilter
