export const fileSize = (fileSize: number) => {
  let size = fileSize

  if (size < 1024) return `${size}B`

  if (size < 1024 * 1024) {
    size = Math.round((size / 1024) * 100) / 100
    return `${size}KB`
  }

  if (size < 1024 * 1024 * 1024) {
    size = Math.round((size / 1024 / 1024) * 100) / 100
    return `${size}MB`
  }

  if (size < 1024 * 1024 * 1024 * 1024) {
    size = Math.round((size / 1024 / 1024 / 1024) * 100) / 100
    return `${size}GB`
  }

  return `${size}`
}
