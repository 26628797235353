import { IButtonProps, Icon, Modal } from '@aurecon-creative-technologies/styleguide'
import { FC } from 'react'
import { useRecoilState } from 'recoil'
import { ShowErrorModal } from '../stores/AppStore'
import Style from '../styles/CommonModals.module.sass'

const ErrorModal: FC = () => {
  const [showUploadErrorModal, setShowUploadErrorModal] = useRecoilState(ShowErrorModal)

  const handleClose = () => {
    setShowUploadErrorModal(false)
  }

  const ModalActions: IButtonProps[] = [
    {
      label: 'Close',
      type: 'primary',
      onClick: handleClose,
      cssClass: Style.secondaryButton,
    },
  ]

  return (
    <Modal
      isShowing={showUploadErrorModal}
      isCloseButton
      onClose={handleClose}
      actions={ModalActions}
      shouldOverlayClose={false}
      size='medium'
      cssClass={Style.commonModal}
      modalPadding={{
        top: '24px',
        right: '24px',
        left: '24px',
      }}
    >
      <>
        <div className={Style.iconRow}>
          <Icon type='error_outlined' colour='#DA3B20' cssClass={Style.icon} />
          <h2>Error</h2>
        </div>
        <p className={Style.modalContent}>Sorry, an unexpected error occurred. Please try again later.</p>
      </>
    </Modal>
  )
}

export default ErrorModal
