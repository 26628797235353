import { Accordion, AccordionPanel, Status } from '@aurecon-creative-technologies/styleguide'
import { FC, useState } from 'react'
import Style from '../../styles/AutomationViewer.module.sass'
import { IAutomationApproval } from '../../models/api/IManageAutomationTableRow'
import { AutomationApprovalDisplayStates } from '../../enums/AutomationApprovalStates'
import { dateToString, htmlEntitiesParser, indexKey, URL_MATCHER_REGEX, URL_SPLITTER_REGEX } from '../../helpers/utils'
import { AppRoleName } from '../../enums/AppRoles'
import { StatusIconColours } from '../../config/config'

interface IVerificationInfoProps {
  automationApprovals: IAutomationApproval[]
}

export const getStatusIconColor = (approval: IAutomationApproval) => {
  switch (approval.ApprovalState.Name) {
    case AutomationApprovalDisplayStates.Approved:
      return StatusIconColours.Approved

    case AutomationApprovalDisplayStates.ActionRequired:
      return StatusIconColours.ActionRequired

    case AutomationApprovalDisplayStates.Rejected:
      return StatusIconColours.Rejected

    case AutomationApprovalDisplayStates.AcceptedForTracking:
      return StatusIconColours.AcceptedForTracking

    default:
      return StatusIconColours.AwaitingApproval
  }
}

const VerificationInformation: FC<IVerificationInfoProps> = (props) => {
  const { automationApprovals } = props
  const sortedApprovals = [...automationApprovals].sort((a, b) => {
    return new Date(a.DateCreated).getTime() - new Date(b.DateCreated).getTime()
  })

  const [open, setOpen] = useState(false)

  const header = (
    <div className={Style.headingHolder}>
      <div className={Style.verificationHeading}>Verification</div>
      <div className={Style.verificationHeadingDate}>
        Automation verified on{' '}
        {dateToString(new Date(sortedApprovals[sortedApprovals.length - 1]?.DateCreated), 'dd/MM/yyyy')}
      </div>
    </div>
  )

  return (
    <Accordion
      activePanelIds={open ? 'main' : ''}
      onPanelToggle={() => setOpen((o) => !o)}
      cssClass={Style.verificationWrapper}
    >
      <AccordionPanel
        label={header}
        panelId='main'
        cssHeaderClass={Style.accordionPanel}
        cssContentClass={Style.accordion}
      >
        <div className={Style.verification}>
          {sortedApprovals.map((approval) => {
            const approverComment = htmlEntitiesParser(approval.ApproverComment) ?? ''
            const commentParts = approverComment.split(URL_SPLITTER_REGEX)
            return (
              <div key={indexKey()} className={Style.verificationHolder}>
                <Status
                  cssClass={Style.verificationStatus}
                  type='success'
                  colour={getStatusIconColor(approval)}
                  label={`${AppRoleName[approval.ApproverRole.Name]} ${approval.ApprovalState.Name} by ${
                    approval.ApprovedBy.UserFullName
                  }`}
                  size='small'
                />
                <p className={Style.verificationDate}>
                  {dateToString(new Date(approval.DateCreated), 'dd/MM/yyyy h:maaa')}
                </p>
                <div className={Style.verificationCommentContainer}>
                  <span className={Style.automationDescription}>
                    {commentParts.map((part) =>
                      URL_MATCHER_REGEX.exec(part) ? (
                        <a
                          className={Style.automationDescription}
                          key={indexKey()}
                          href={part}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {part}
                        </a>
                      ) : (
                        part
                      ),
                    )}
                  </span>
                </div>
              </div>
            )
          })}
        </div>
      </AccordionPanel>
    </Accordion>
  )
}

export default VerificationInformation
