import { FC, useState } from 'react'
import Style from '../styles/Manage.module.sass'
import { appInsights } from '../api/AppInsights'
import { Container, FormInput, Icon, Button } from '@aurecon-creative-technologies/styleguide'
import Page from '../components/Page'
import { useRecoilState, useRecoilValue } from 'recoil'
import { buttonType } from '../enums/ButtonType'
import classNames from 'classnames'
import { TabsEnum } from '../enums/Manage'
import {
  ActiveManageTabState,
  ManageAutomationFilters,
  ManagePageCount,
  ManageSearchState,
} from '../stores/ManageAutomationStore'
import ManageAutomationsTable from '../components/ManageAutomations/ManageAutomationsTable'
import AutomationFilter from '../components/AutomationFilter'

const Manage: FC = () => {
  if (appInsights) appInsights.trackPageView({ name: 'Manage' })
  const [activeTab, setActiveTab] = useRecoilState(ActiveManageTabState)
  const maxPages = useRecoilValue(ManagePageCount)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [activeButton, setActiveButton] = useState<string>('')

  // Filters
  const [searchValue, setSearchValue] = useRecoilState(ManageSearchState)

  function handleClick(buttonId: string) {
    setActiveButton(buttonId)
    if (buttonId == buttonType.START_BUTTON) {
      setCurrentPage(1)
    }

    if (buttonId == buttonType.FORWARD_BUTTON) {
      setCurrentPage(currentPage + 1)
    }

    if (buttonId == buttonType.BACK_BUTTON) {
      setCurrentPage(currentPage - 1)
    }

    if (buttonId == buttonType.END_BUTTON) {
      setCurrentPage(maxPages)
    }
  }

  const handleChange = (text: string) => {
    setSearchValue(text)
  }

  const buttonClasses = (buttonId: string): string => {
    return classNames({
      [Style.active]: activeButton === buttonId,
      [Style.pageButtons]: true,
    })
  }

  return (
    <Page menu manage>
      <Container cssClass={Style.layoutContainer} fluid>
        <header className={Style.hero}>
          <h2>Manage Automation</h2>
          <div className={Style.tabs}>
            <div
              className={`${Style.tab} ${activeTab === TabsEnum.APPROVALS ? Style.active : ''}`}
              onClick={() => {
                setCurrentPage(1)
                setActiveTab(TabsEnum.APPROVALS)
              }}
              role='none'
            >
              Automation Approvals
            </div>
            <div
              className={`${Style.tab} ${activeTab === TabsEnum.MY_CONSTRIBUTIONS ? Style.active : ''}`}
              onClick={() => {
                setCurrentPage(1)
                setActiveTab(TabsEnum.MY_CONSTRIBUTIONS)
              }}
              role='none'
            >
              My Contributions
            </div>
          </div>
        </header>
        <div className={Style.searchfilterContainer}>
          <div className={Style.searchBox}>
            <FormInput
              type='text'
              placeholder={`Search Automation here...`}
              onChange={(e) => handleChange(e)}
              value={searchValue}
              cssClass={Style.searchInput}
            />
            <Icon className={Style.icon} type='search' outlined />
          </div>
        </div>
        <AutomationFilter filterState={ManageAutomationFilters} />
        {activeTab === TabsEnum.MY_CONSTRIBUTIONS ? (
          <ManageAutomationsTable currentPage={currentPage} type={TabsEnum.MY_CONSTRIBUTIONS} />
        ) : (
          <ManageAutomationsTable currentPage={currentPage} type={TabsEnum.APPROVALS} />
        )}

        <div className={Style.sectionPage}>
          <div className={Style.pageReference}>
            <p>
              Page <input type='text' value={currentPage} onChange={(e) => setCurrentPage(Number(e.target.value))} /> of{' '}
              {maxPages}
            </p>
          </div>
          <div className={Style.pageButtonsContainer}>
            <Button
              onClick={() => handleClick(buttonType.START_BUTTON)}
              type='icon-round-secondary'
              size='medium'
              icon='keyboard_double_arrow_left'
              disabled={currentPage === 1}
              cssClass={Style.pageButtons}
            />
            <Button
              onClick={() => handleClick(buttonType.BACK_BUTTON)}
              type='icon-round-secondary'
              size='medium'
              icon='keyboard_arrow_left'
              disabled={currentPage <= 1}
              cssClass={buttonClasses(buttonType.BACK_BUTTON)}
            />
            <Button
              onClick={() => handleClick(buttonType.FORWARD_BUTTON)}
              type='icon-round-secondary'
              size='medium'
              icon='keyboard_arrow_right'
              disabled={currentPage === maxPages}
              cssClass={buttonClasses(buttonType.FORWARD_BUTTON)}
            />
            <Button
              onClick={() => handleClick(buttonType.END_BUTTON)}
              type='icon-round-secondary'
              size='medium'
              icon='keyboard_double_arrow_right'
              disabled={currentPage === maxPages}
              cssClass={Style.pageButtons}
            />
          </div>
        </div>
      </Container>
    </Page>
  )
}

export default Manage
