import { IButtonProps, Icon, Modal } from '@aurecon-creative-technologies/styleguide'
import { FC } from 'react'
import { useRecoilState } from 'recoil'
import { ShowUploadScriptLibraryModal } from '../../stores/AutomationUploadStore'
import Style from '../../styles/CommonModals.module.sass'

const ScriptLibraryModal: FC = () => {
  const [showUploadScriptLibraryModal, setShowUploadScriptLibraryModal] = useRecoilState(ShowUploadScriptLibraryModal)

  const handleClose = () => {
    setShowUploadScriptLibraryModal(false)
    window.location.hash = '/'
  }

  const handleConfirm = () => {
    setShowUploadScriptLibraryModal(false)
  }

  const ModalActions: IButtonProps[] = [
    {
      label: 'Close',
      onClick: handleClose,
      cssClass: Style.secondaryButton,
    },
    {
      label: 'Upload',
      type: 'primary',
      onClick: handleConfirm,
      cssClass: Style.primaryButton,
    },
  ]

  return (
    <Modal
      isShowing={showUploadScriptLibraryModal}
      isCloseButton
      onClose={handleClose}
      actions={ModalActions}
      shouldOverlayClose={false}
      size='medium'
      cssClass={Style.commonModal}
      modalPadding={{
        top: '24px',
        right: '24px',
        left: '24px',
      }}
    >
      <>
        <div className={Style.iconRow}>
          <Icon type='check_circle_outlined' colour='#85C446' cssClass={Style.icon} />
          <h2>Success</h2>
        </div>
        <p className={Style.modalContent}>
          You have been redirected to Script Library to complete the upload process. Do you want to upload another
          Automation?
        </p>
      </>
    </Modal>
  )
}

export default ScriptLibraryModal
