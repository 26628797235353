import { IButtonProps, Modal } from '@aurecon-creative-technologies/styleguide'
import { FC } from 'react'
import { useRecoilState } from 'recoil'
import { ShowUploadCancelModal } from '../../stores/AutomationUploadStore'
import Style from '../../styles/CommonModals.module.sass'

interface CancelModalProps {
  onYes?: () => void
  onNo?: () => void
}

const CancelModal: FC<CancelModalProps> = ({ onYes, onNo }) => {
  const [showUploadCancelModal, setShowUploadCancelModal] = useRecoilState(ShowUploadCancelModal)

  const handleClose = () => {
    setShowUploadCancelModal(false)
  }

  const handleYes = () => {
    handleClose()
    onYes?.()
  }

  const handleNo = () => {
    handleClose()
    onNo?.()
  }

  const ModalActions: IButtonProps[] = [
    {
      label: 'Cancel',
      onClick: handleNo,
      cssClass: Style.secondaryButton,
    },
    {
      label: 'Leave',
      onClick: handleYes,
      cssClass: Style.primaryButton,
    },
  ]

  return (
    <Modal
      isShowing={showUploadCancelModal}
      isCloseButton
      onClose={handleClose}
      actions={ModalActions}
      shouldOverlayClose={false}
      size='medium'
      cssClass={Style.commonModal}
      modalPadding={{
        top: '24px',
        right: '24px',
        left: '24px',
      }}
    >
      <>
        <div className={Style.headerRow}>Unsaved Changes</div>
        <p className={Style.modalContent}>You have unsaved changes. Are you sure you want to leave this page?</p>
      </>
    </Modal>
  )
}

export default CancelModal
