import { Grid } from '@aurecon-creative-technologies/styleguide'
import { FC } from 'react'
import Style from '../../styles/AutomationViewer.module.sass'
import { htmlEntitiesParser } from '../../helpers/utils'

interface DetailRowProps {
  title: string
  children?: JSX.Element[] | JSX.Element | React.ReactNode
}

const DetailRow: FC<DetailRowProps> = (props) => {
  return (
    <Grid row left xs={12} cssClass={Style.detailRow}>
      <Grid xs={12} md={12} lg={2} center cssClass={Style.fieldTitle}>
        <b>{props.title}</b>
      </Grid>
      <Grid xs={12} md={12} lg={10} center cssClass={Style.content}>
        {typeof props.children === 'string' ? htmlEntitiesParser(props.children) : props.children}
      </Grid>
    </Grid>
  )
}

export default DetailRow
