import { atom, selector } from 'recoil'
import { getAppFlags, getAppVersion } from '../api/AppService'
import { TagTypes } from '../enums/TagTypes'
import { IDropdownItemProps } from '@aurecon-creative-technologies/styleguide'
import { IUser } from '../models/IUser'

export const AppVersion = atom({
  key: 'version',
  default: '0.0.0',
  effects: [
    ({ setSelf }) => {
      getAppVersion().then((response) => {
        setSelf(response.data?.version ?? '0.0.0')
      })
    },
  ],
})

export const TermsModal = atom({
  key: 'terms_modal',
  default: false,
})

export const SearchValue = atom({
  key: 'search_value',
  default: '',
})

export const AutoSearchValue = atom({
  key: 'auto_search_value',
  default: '',
})

export const ShowErrorModal = atom<boolean>({
  key: 'show_error_modal',
  default: false,
})

export const SearchAutomationFilters = atom({
  key: 'search_automation_filters',
  default: {
    [TagTypes.SOURCE]: [] as IDropdownItemProps[],
    [TagTypes.CAPABILITY]: [] as IDropdownItemProps[],
    [TagTypes.PRACTICE]: [] as IDropdownItemProps[],
    [TagTypes.REGION]: [] as IDropdownItemProps[],
  },
})

export const CurrentUser = atom<IUser | null>({
  key: 'current_user',
  default: null,
})

export const AppFlags = selector({
  key: 'app_flags',
  get: async () => {
    const response = await getAppFlags()
    return response.data || {}
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})
