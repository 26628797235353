import { HttpContentType, HttpMethod } from '../enums/ApiRequestConstants'
import { getAsync } from '../helpers/apiRequest'
import { IResponse } from '../models/api/IResponse'
import { ISasRequestModel } from '../models/api/ISasRequestModal'
import { TokenExpiryWrapper } from './TokenManager'

export const getMediaBlobUrl = async (container: string, blobname: string): Promise<string | null> => {
  try {
    const url = `/storage/${container}/${blobname}`
    const response = await fetch(url, {
      headers: { 'Content-Type': HttpContentType.TEXT },
      method: HttpMethod.GET,
    })

    if (response?.ok) return response.text()
  } catch (error) {
    console.log('** Error fetching data...')
    console.log(error)
  }
  return null
}

export const getMediaBlob = TokenExpiryWrapper(
  async (request: ISasRequestModel): Promise<IResponse<Blob>> =>
    getAsync<ISasRequestModel, Blob>(
      `/v1/storage/download/${request.container}/${request.automationId}/${request.blobName}`,
      request,
      HttpContentType.BLOB,
    ),
  [],
  null,
)

export const deleteMacroFile = TokenExpiryWrapper(
  async (filename: string): Promise<boolean> => {
    try {
      const url = `/storage/delete/macro`
      const response = await fetch(url, {
        headers: { 'Content-Type': HttpContentType.JSON },
        method: HttpMethod.POST,
        body: JSON.stringify({ filename }),
      })

      return response?.ok ?? false
    } catch (error) {
      console.log('** Error fetching data...')
      console.log(error)
    }
    return false
  },
  [],
  null,
)

export const getAutomationAssetsRequest = async (request: ISasRequestModel) => {
  try {
    const response = await fetch(`/v1/storage/${request.container}/${request.blobName}`, {
      method: 'get',
      headers: { Authorization: `Bearer ${request.token}` },
    })

    if (!response.ok) {
      return null
    } else {
      const arrayBuffer = await response.arrayBuffer()
      const blob = new Blob([arrayBuffer])
      const imageDataUrl = URL.createObjectURL(blob)
      return imageDataUrl
    }
  } catch (error) {
    console.log('** Error fetching assets...')
    return null
  }
}

export const getAutomationAssets = TokenExpiryWrapper(getAutomationAssetsRequest, [], null)
