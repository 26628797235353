import { atom } from 'recoil'

export const ShowUploadScriptLibraryModal = atom<boolean>({
  key: 'show_scriptlibrary_modal',
  default: false,
})

export const ShowUploadWarningModal = atom<boolean>({
  key: 'show_upload_warning_modal',
  default: false,
})

export const ShowUploadCancelModal = atom<boolean>({
  key: 'show_upload_cancel_modal',
  default: false,
})

export const ShowUploadSubmitModal = atom<boolean>({
  key: 'show_upload_submit_modal',
  default: false,
})
