import { Loader } from '@aurecon-creative-technologies/styleguide'
import { FC } from 'react'

import Style from '../styles/LoadingScreen.module.sass'

interface ILoadingScreenProps {
  text?: string
  size?: 'small' | 'extra small' | 'medium' | 'large' | 'extra large'
}

const LoadingScreen: FC<ILoadingScreenProps> = (props) => {
  return (
    <div className={Style.loader}>
      <Loader label={props.text ?? 'Loading...'} size={props.size} />
    </div>
  )
}

export default LoadingScreen
