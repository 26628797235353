import { atom, selector } from 'recoil'
import { TabsEnum } from '../enums/Manage'
import { getManageAutomationsTableRows, getMyContributions, postSearchAutomation } from '../api/AutomationService'
import { ResponseData } from '../models/api/IResponse'
import { IDropdownItemProps } from '@aurecon-creative-technologies/styleguide'
import { IManageAutomationTableRow } from '../models/api/IManageAutomationTableRow'
import { IGetAutomationResponse } from '../models/api/IAutomationRequest'
import { TagTypes } from '../enums/TagTypes'

interface IFilterResultsProps {
  automationList: IManageAutomationTableRow[] | null
  search: string
  practicesFilter: IDropdownItemProps[]
  capabilitiesFilter: IDropdownItemProps[]
  regionsFilter: IDropdownItemProps[]
  sourcesFilter: IDropdownItemProps[]
}

export interface IFilterState {
  source: IDropdownItemProps[]
  capability: IDropdownItemProps[]
  practice: IDropdownItemProps[]
  region: IDropdownItemProps[]
}

const filterResults = (props: IFilterResultsProps) => {
  const { automationList, practicesFilter, capabilitiesFilter, regionsFilter, sourcesFilter, search } = props

  return automationList?.filter((automation) => {
    return (
      automation.Title.trim().toLowerCase().includes(search.trim().toLowerCase()) &&
      (practicesFilter.length === 0 ||
        automation.AutomationPractices?.some((practice) =>
          practicesFilter.some((p) => p.label?.toString().toLowerCase() === practice.Name.toLowerCase()),
        )) &&
      (capabilitiesFilter.length === 0 ||
        automation.AutomationCapabilities?.some((capability) =>
          capabilitiesFilter.some((c) => c.id?.toString() === capability.Id.toString()),
        )) &&
      (regionsFilter.length === 0 ||
        automation.AutomationCapabilities?.some((region) =>
          regionsFilter.some((regionFilter) => regionFilter.id?.toString() === region.Id.toString()),
        )) &&
      (sourcesFilter.length === 0 ||
        sourcesFilter.some((source) => source.id.toString() === automation.AutomationSource?.Id.toString()))
    )
  })
}

export const filtersLocalStorageEffect =
  (key: string) =>
  ({
    setSelf,
    onSet,
  }: {
    setSelf: (value: IFilterState) => void
    onSet: (callback: (newValue: IFilterState, _: unknown, isReset: boolean) => void) => void
  }) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue))
    }

    onSet((newValue: IFilterState, _: unknown, isReset: boolean) => {
      isReset ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(newValue))
    })
  }

export const ActiveManageTabState = atom<number>({
  key: 'current_active_tab_manage_page',
  default: TabsEnum.APPROVALS,
})

export const ManageSearchState = atom<string>({
  key: 'manage_search_state',
  default: '',
})

export const FetchMyContributions = selector({
  key: 'fetch_automation_my_contributions',
  get: async () => {
    try {
      const results = await getMyContributions({})
      return ResponseData(results)
    } catch (error) {
      return []
    }
  },
})

export const FetchPendingApprovalList = selector({
  key: 'fetch_automation_pending_approval_list',
  get: async () => {
    try {
      const results = await getManageAutomationsTableRows({})
      return ResponseData(results)
    } catch (error) {
      console.log(error)
      return []
    }
  },
})

export const ManageAutomationFilters = atom({
  key: 'manage_automation_filters',
  default: {
    [TagTypes.SOURCE]: [] as IDropdownItemProps[],
    [TagTypes.CAPABILITY]: [] as IDropdownItemProps[],
    [TagTypes.PRACTICE]: [] as IDropdownItemProps[],
    [TagTypes.REGION]: [] as IDropdownItemProps[],
  },
})

export const MyContributions = selector({
  key: 'automation_my_contributions',
  get: async ({ get }) => {
    const automationList = get(FetchMyContributions)
    const search = get(ManageSearchState)
    const manageFilters = get(ManageAutomationFilters)

    return filterResults({
      automationList,
      search,
      practicesFilter: manageFilters.practice,
      sourcesFilter: manageFilters.source,
      capabilitiesFilter: manageFilters.capability,
      regionsFilter: manageFilters.region,
    })
  },
})

export const PendingApprovalList = selector({
  key: 'automation_pending_approval_list',
  get: async ({ get }) => {
    const automationList = get(FetchPendingApprovalList)
    const search = get(ManageSearchState)
    const manageFilters = get(ManageAutomationFilters)

    return filterResults({
      automationList,
      search,
      practicesFilter: manageFilters.practice,
      sourcesFilter: manageFilters.source,
      capabilitiesFilter: manageFilters.capability,
      regionsFilter: manageFilters.region,
    })?.filter((automation: IManageAutomationTableRow) => !automation.HasApprovalFromSameRole)
  },
})

export const ApprovedAutomationsAndScripts = selector({
  key: 'approved_automations_and_scripts',
  get: async () => {
    try {
      const results = await postSearchAutomation({ searchText: '' })
      const automations = ResponseData(results) as IGetAutomationResponse[]
      const sortedAutomations = [...automations]?.sort(
        (a, b) => new Date(b.VerificationDate).getTime() - new Date(a.VerificationDate).getTime(),
      )
      return sortedAutomations
    } catch (error) {
      console.log(error)
      return []
    }
  },
})

export const ManagePageCount = atom<number>({
  key: 'automation_manage_page_count',
  default: 1,
})
