//This should match the values in the LUAutomationState table
export enum AutomationStates {
  DRAFT = 1,
  IN_REVIEW = 2,
  APPROVED = 3,
  REJECTED = 4,
  ACCEPTED_FOR_TRACKING = 5,
}

export enum AutomationStateNames {
  DRAFT = 'Draft',
  IN_REVIEW = 'In Review',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  ACCEPTED_FOR_TRACKING = 'Accepted For Tracking',
}
