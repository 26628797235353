import config, { AppRolesEnum } from '../config/config'
import { User } from '@auth0/auth0-react'

export const isApproverUser = (user: User | undefined): boolean => {
  if (!user?.[config.AUTH0_ROLES]) return false
  const userRoles = getUserRoles(user)
  const approverRoles = Object.values(AppRolesEnum)
  const hasApproverRole = approverRoles.some((role) => userRoles.includes(role))
  return hasApproverRole
}

export const getUserRoles = (user: User | undefined): string[] => {
  return user?.[config.AUTH0_ROLES] ?? []
}
