import { FC, useEffect, useRef, useState } from 'react'
import Style from '../styles/SearchComboBox.module.sass'
import { ComboBox, Grid, IOption, Pill, Tooltip } from '@aurecon-creative-technologies/styleguide'
import classNames from 'classnames'
import { ResponseData } from '../models/api/IResponse'
import { searchUsers } from '../api/UserService'
import debounce from 'debounce'
import { AutomationOriginators } from '../models/api/IAutomationRequest'

interface IUserComboBoxProps {
  onSelectedItem: (users: string[]) => void
  selectedItems: AutomationOriginators[]
  label: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  error?: string
}

const UserComboBox: FC<IUserComboBoxProps> = (props) => {
  const { label, placeholder, onSelectedItem, required, disabled, selectedItems, error } = props
  const [users, setUsers] = useState<IOption[]>()
  const [selectedOriginators, setSelectedOriginators] = useState<IOption[]>([])
  const originatorRef = useRef<HTMLInputElement | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [val, setVal] = useState('')

  useEffect(() => {
    setSelectedOriginators(
      selectedItems?.map((originator) => ({ id: originator.UserId, value: originator.User.UserFullName })) ?? [],
    )
  }, [selectedItems])

  const handleRemoveOriginator = (key: string) => {
    const updatedOrignators = selectedOriginators.filter((x) => x.id !== key)
    setSelectedOriginators(updatedOrignators)
    onSelectedItem(updatedOrignators.map((s) => s.id.toString()))
  }

  const handleAddOriginator = (newOption: IOption | null) => {
    if (!newOption || !originatorRef.current) return
    if (selectedOriginators.find((s) => s.id === newOption.id)) return
    const updatedOrignators = [...selectedOriginators, newOption]
    setSelectedOriginators(updatedOrignators)
    onSelectedItem(updatedOrignators.map((s) => s.id.toString()))
    originatorRef.current.value = ''
    setUsers([])
    setVal('')
    originatorRef.current.blur()
  }

  const onSearch = debounce(async (event: React.FormEvent<HTMLDivElement>) => {
    if (!originatorRef.current) return
    const value = (event.target as HTMLInputElement).value
    if (val === value) return
    setVal(value)
    originatorRef.current.value = value
    if (value.length < 3) {
      setUsers([])
      return
    }
    setLoading(true)
    const userList = ResponseData(await searchUsers({ email: value })) || []
    const filteredUsers = userList.filter((user) => !selectedOriginators.map((so) => so.id).includes(user.auth0UserId))
    setUsers(filteredUsers.map((u) => ({ id: u.auth0UserId ?? '', value: u.name ?? '' })))
    setLoading(false)
  }, 500)

  const originatorPillClasses = classNames({
    [Style.metadataPill]: true,
    [Style.item]: true,
  })

  const comboBoxClasses = classNames({
    [Style.comboBox]: true,
    [Style.loading]: loading,
  })

  const showOriginatorPills = () => {
    return selectedOriginators.map((item) => (
      <Pill
        key={item.id}
        size='medium'
        cssClass={originatorPillClasses}
        onClose={() => handleRemoveOriginator(item.id.toString())}
      >
        {item.value}
      </Pill>
    ))
  }

  return (
    <Grid item xs={12} cssClass={Style.metadataRow}>
      <Grid item xs={12} cssClass={Style.metadataFieldRow}>
        <Tooltip
          padding={32}
          cssClass='tooltip-theme-dark'
          defaultUp
          show='Identify the originator of the idea for the automation. This could be the same as primary author/s'
        >
          <div className={Style.metadataFieldlabel}>{label}</div>
        </Tooltip>
      </Grid>
      {selectedOriginators.length > 0 && (
        <Grid item xs={12} cssClass={Style.metadataFieldRow}>
          <div className={Style.metadataPillsBox}>{showOriginatorPills()}</div>
        </Grid>
      )}
      <Grid item xs={12} cssClass={Style.metadataFieldRow}>
        <div className={comboBoxClasses} onChange={onSearch}>
          <ComboBox
            options={users || []}
            onSelect={handleAddOriginator}
            required={required}
            selected={val}
            disabled={disabled}
            placeholder={placeholder}
            ref={originatorRef}
            icon={loading ? 'sync' : undefined}
          />
        </div>
        {error && <p className={Style.errorMessage}>{error}</p>}
      </Grid>
    </Grid>
  )
}

export default UserComboBox
