import { IKeyValue } from '../models/IKeyValue'

export const PlatformInterfaces: IKeyValue[] = [
  { key: '1', value: 'One Space' },
  { key: '2', value: 'ProjectWise' },
  { key: '3', value: 'BIM360' },
  { key: '4', value: '12d Synergy' },
  { key: '5', value: 'Revit' },
  { key: '6', value: 'SharePoint' },
  { key: '7', value: 'AutoCAD' },
]
