import { IKeyValue } from '../models/IKeyValue'

export const Regions: IKeyValue[] = [
  { key: '1', value: 'Global' },
  { key: '2', value: 'ASIA' },
  { key: '3', value: 'NSW / ACT' },
  { key: '4', value: 'NZ' },
  { key: '5', value: 'QLD' },
  { key: '6', value: 'SAVI' },
  { key: '7', value: 'WANT' },
]
