import { FC, useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import Cookies from 'universal-cookie'
import { useAuth0 } from '@auth0/auth0-react'
import { add } from 'date-fns'
import { TermsModal } from '../stores/AppStore'
import config from '../config/config'
import { useLocation } from 'react-router-dom'

import Style from '../styles/TermsCookiesModal.module.sass'
import { Modal } from './Modal'
import { Button } from '@aurecon-creative-technologies/styleguide'
import { AppRoutes } from '../enums/AppRoutes'

const cookies = new Cookies()

const TermsCookiesModal: FC = () => {
  const { logout } = useAuth0()
  const { pathname } = useLocation()
  const [acceptedCookies, setAcceptedCookies] = useState(false)
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [termsModal, setTermsModal] = useRecoilState(TermsModal)

  useEffect(() => {
    const c = cookies.get('atc.cookies')
    const t = cookies.get('atc.terms')

    if (c?.accepted) setAcceptedCookies(true)
    if (t?.accepted) setAcceptedTerms(true)
  }, [])

  useEffect(() => {
    setTermsModal(!acceptedTerms || !acceptedCookies)
  }, [acceptedCookies, acceptedTerms, setTermsModal])

  const handleAcceptTerms = () => {
    cookies.set('atc.terms', { accepted: true }, { path: '/', expires: add(new Date(), { years: 1 }) })
    setAcceptedTerms(true)
  }

  const handleAcceptCookies = () => {
    cookies.set('atc.cookies', { accepted: true }, { path: '/', expires: add(new Date(), { years: 1 }) })
    setAcceptedCookies(true)
  }

  const handleDeclineTerms = () => {
    cookies.set('atc.terms', { accepted: false }, { path: '/', expires: add(new Date(), { years: 1 }) })

    setAcceptedTerms(false)
    logout({ returnTo: config.AUTH0_LOGOUT_RETURN })
  }

  const handleCloseModal = () => {
    // required callback for modal
  }

  const noOverlayPages =
    pathname.includes('termsandconditions') || pathname.includes('cookie') || pathname.includes('privacy')

  return (
    <>
      {!noOverlayPages && (
        <Modal
          isShowing={termsModal}
          onClose={handleCloseModal}
          isCloseButton={false}
          size='medium'
          cssClass={`is-cookie-modal ${noOverlayPages ? 'no-overlay' : ''}`}
          isOverlay={!noOverlayPages}
        >
          <div className={Style.modalBody}>
            {!acceptedTerms && (
              <div className={Style.tcContainer}>
                <div className={Style.tcText}>
                  <p>
                    Please consent to the{' '}
                    <a href={`/#/${AppRoutes.TERMS_AND_CONDITION}`} target='_blank' rel='noreferrer'>
                      Terms & Conditions
                    </a>{' '}
                    and{' '}
                    <a href={`/#/${AppRoutes.PRIVACY}`} target='_blank' rel='noreferrer'>
                      Privacy Policy
                    </a>
                    {'.'}
                  </p>
                </div>
                <div className={Style.tcButtonBox}>
                  <Button
                    label='Decline'
                    type='secondary'
                    onClick={handleDeclineTerms}
                    cssClass={Style.tcDeclineButton}
                  />
                  <Button label='Accept' onClick={handleAcceptTerms} cssClass={Style.tcAcceptButton} />
                </div>
              </div>
            )}
            {!acceptedCookies && (
              <div className={Style.tcContainer}>
                <div className={Style.tcText}>
                  <p>
                    We use cookies to ensure that we give you the best experience on our application.{' '}
                    <a href='/#/cookies' target='_blank' rel='noreferrer'>
                      Find out more
                    </a>
                  </p>
                </div>
                <div className={Style.tcButtonBox}>
                  <Button label='Accept' onClick={handleAcceptCookies} cssClass={Style.tcAcceptButton} />
                </div>
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  )
}

export default TermsCookiesModal
