import { Button, Loader, Tooltip } from '@aurecon-creative-technologies/styleguide'
import { FC, ReactElement, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { BLOB_THUMBNAILS_CONTAINER, MAX_TAGS_ON_CARDS, UI_WIDTH_COLLAPSE } from '../config/config'
import Tag, { ITagProps } from './Tag'
import { getTagLabel, indexKey } from '../helpers/utils'
import DefaultThumbnail from '../assets/featured_card1.png'
import { AutomationUploadTypes } from '../enums/AutomationUploadTypes'
import { getAutomationAssets } from '../api/StorageService'
import Likes from './Likes'

import Style from '../styles/AutomationCard.module.sass'

export interface IAutomationCardProps {
  title: string
  id: string
  creationDate: string
  description: string
  tags: ITagProps[]
  thumbnail?: string
  uploadType?: number | null
  onClick?: () => void
  viewLink?: string
}

const TAG_TO_DISPLAY_PER_TYPE = 1

export const renderTags = (tags: ITagProps[], isDesktop: boolean, inSearch?: boolean): ReactElement[] => {
  const groupedTags = tags.reduce((acc, tag) => {
    if (!acc[tag.type]) {
      acc[tag.type] = []
    }
    acc[tag.type].push(tag)
    return acc
  }, {} as { [key: string]: ITagProps[] })

  return Object.keys(groupedTags)
    .slice(0, MAX_TAGS_ON_CARDS)
    .map((type, typeIndex) => {
      const key = indexKey()
      if (groupedTags[type].length === TAG_TO_DISPLAY_PER_TYPE) {
        const tagData = groupedTags[type][0]
        return (
          <Tag
            key={key}
            id={tagData.id}
            type={tagData.type}
            label={getTagLabel({
              label: tagData.label,
              currentIndex: typeIndex,
              tagsLength: TAG_TO_DISPLAY_PER_TYPE,
              isDesktop,
            })}
            name={tagData.label}
            size='small'
            inSearch={inSearch}
          />
        )
      } else {
        const displayedTag = groupedTags[type][0]
        const hiddenTag = tags.slice(1, groupedTags[type].length)

        return (
          <Tooltip
            key={`${key}_tooltip`}
            defaultUp
            padding={32}
            cssClass='tooltip-theme-dark'
            offset={{
              left: 20,
              top: 0,
            }}
            show={
              <>
                {hiddenTag.map((t) => {
                  return (
                    <span
                      style={{
                        fontSize: '14px',
                        display: 'block',
                        lineHeight: '20px',
                        fontWeight: 400,
                      }}
                      key={t.id}
                    >
                      {t.label}
                    </span>
                  )
                })}
              </>
            }
          >
            <Tag
              key={key}
              id={displayedTag.id}
              type={displayedTag.type}
              label={getTagLabel({
                label: displayedTag.label,
                currentIndex: 0,
                tagsLength: groupedTags[type].length + TAG_TO_DISPLAY_PER_TYPE,
                isDesktop,
              })}
              name={displayedTag.label}
              size='small'
            />
          </Tooltip>
        )
      }
    })
}

const AutomationCard: FC<IAutomationCardProps> = (props) => {
  const isDesktop = useMediaQuery({ minWidth: UI_WIDTH_COLLAPSE })
  const [thumbnailUrl, setThumbnailUrl] = useState<string>('')
  const [isThumbnailLoading, setIsThumbnailLoading] = useState<boolean>(false)

  useEffect(() => {
    const getThumbnail = async () => {
      if (!props.thumbnail) setThumbnailUrl(DefaultThumbnail)
      if (props.thumbnail) {
        setIsThumbnailLoading(true)
        const url = await getAutomationAssets({
          container: BLOB_THUMBNAILS_CONTAINER,
          blobName: props.thumbnail,
        })

        if (url) setThumbnailUrl(url)
        else setThumbnailUrl(DefaultThumbnail)

        setIsThumbnailLoading(false)
      }
    }

    getThumbnail()
  }, [props.thumbnail])

  return (
    <div className={Style.automationCard} onClick={props.onClick} role='none'>
      <div className={Style.thumbnailContainer}>
        <div className={Style.thumbnailSection}>
          {isThumbnailLoading ? (
            <Loader cssClass={Style.loader} size={'extra small'} />
          ) : (
            <img alt='img' src={thumbnailUrl} />
          )}
        </div>
        {props.uploadType === AutomationUploadTypes.UPLOAD_FILE ? (
          <Button type='icon-round' size='extra small' icon='download' cssClass={Style.cardButtons} />
        ) : (
          <Button
            type='icon-round'
            size='extra small'
            icon='open_in_new'
            cssClass={Style.cardButtons}
            onClick={(event) => {
              event.stopPropagation()
              if (props.viewLink) {
                window.open(props.viewLink, '_blank')
              }
            }}
          />
        )}
      </div>

      <div className={Style.automationTitle}>{props.title}</div>
      <div className={Style.metadata}>
        <Likes id={props.id} />
        <div className={Style.creationDate}>{props.creationDate}</div>
      </div>
      <div className={Style.description}>{props.description}</div>

      <div className={Style.tags}>{renderTags(props.tags, isDesktop)}</div>
    </div>
  )
}

export default AutomationCard
