import { getAsync } from '../helpers/apiRequest'
import { IGetAutomationResponse } from '../models/api/IAutomationRequest'
import { IBodyRequestModel } from '../models/api/IBodyRequestModel'
import { IResponse } from '../models/api/IResponse'
import { IGetScriptsResponse } from '../models/api/IScriptLibaryModel'
import { TokenExpiryWrapper } from './TokenManager'

interface IGetScriptsRequest extends IBodyRequestModel {
  order: string
  page?: number
}

export const getScriptLibraryScripts = TokenExpiryWrapper(
  async (request: IGetScriptsRequest): Promise<IResponse<IGetScriptsResponse>> =>
    getAsync(`/v1/automations/scripts?order=${request.order}&page=${request.page}`, request),
  [],
  null,
)

export const getRecommendedAutomations = TokenExpiryWrapper(
  async (request: IGetScriptsRequest): Promise<IResponse<IGetAutomationResponse[]>> =>
    getAsync(`/v1/automations/scripts/recommended?order=${request.order}`, request),
  [],
  null,
)
