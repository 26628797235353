import { IKeyValue } from '../models/IKeyValue'
import { AutomationUploadTypes } from './AutomationUploadTypes'

export const AutomationSourceUploadTypes: IKeyValue[] = [
  { key: 'Python', value: AutomationUploadTypes.SCRIPT_LIBRARY },
  { key: 'Dynamo', value: AutomationUploadTypes.SCRIPT_LIBRARY },
  { key: 'Rhino', value: AutomationUploadTypes.SCRIPT_LIBRARY },
  { key: 'Grasshopper', value: AutomationUploadTypes.SCRIPT_LIBRARY },
  { key: 'Cpython', value: AutomationUploadTypes.SCRIPT_LIBRARY },
  { key: 'Csharp', value: AutomationUploadTypes.SCRIPT_LIBRARY },
  { key: 'iFC', value: AutomationUploadTypes.SCRIPT_LIBRARY },
  { key: 'Microsoft VBA Code (Macro)', value: AutomationUploadTypes.UPLOAD_FILE },
  { key: 'Power Automate', value: AutomationUploadTypes.LINK },
  { key: 'Power Apps', value: AutomationUploadTypes.LINK },
  { key: 'Other', value: AutomationUploadTypes.LINK },
  { key: 'Office Scripts', value: AutomationUploadTypes.UPLOAD_FILE },
  { key: 'Microsoft Excel', value: AutomationUploadTypes.UPLOAD_FILE },
]
