import { Button, Grid, Icon, useToast } from '@aurecon-creative-technologies/styleguide'
import { FC, useMemo } from 'react'
import Style from '../../styles/AutomationViewer.module.sass'
import DetailRow from './DetailRow'
import Tag from '../Tag'
import { IGetAutomationResponse } from '../../models/api/IAutomationRequest'
import {
  dateTimeFormat,
  dateToString,
  htmlEntitiesParser,
  indexKey,
  URL_MATCHER_REGEX,
  URL_SPLITTER_REGEX,
} from '../../helpers/utils'
import { AppRolesEnum } from '../../config/config'
import { AutomationStateNames } from '../../enums/AutomationStates'
import { StatusIcon } from '../ManageAutomations/StatusIcon'
import { handleDownload } from '../../helpers/commonFunctions'
import { AutomationUploadTypes } from '../../enums/AutomationUploadTypes'
import { TagTypes } from '../../enums/TagTypes'

interface AutomationInformationProps {
  showHeader?: boolean
  automationDetails?: IGetAutomationResponse
  isScriptLibrary?: boolean
  isStreamliner?: boolean
  showDivider?: boolean
}

const AutomationInformation: FC<AutomationInformationProps> = (props) => {
  const automationDetails = props.automationDetails
  const { addToast } = useToast()
  const now = new Date()
  const showToast = () => {
    addToast({
      type: 'success',
      message: 'Link Copied To Clipboard',
      timeout: 2000,
      datetime: Number(dateToString(now, dateTimeFormat)),
    })
  }

  const automationVerifiers = useMemo(() => {
    if (!automationDetails?.AutomationApprovals) return []
    const filteredUsers = [
      ...new Map(
        automationDetails.AutomationApprovals.map((item) => [item?.ApprovedBy?.AuthFederationId, item]),
      ).values(),
    ]

    return filteredUsers
  }, [automationDetails])

  const handleCopy = async () => {
    const currentUrl = window.location.href
    await navigator.clipboard.writeText(currentUrl)
    showToast()
  }

  const descriptionParts = htmlEntitiesParser(automationDetails?.Description ?? '')?.split(URL_SPLITTER_REGEX) ?? []

  return (
    <>
      {props.showDivider && <div className={Style.divider} />}
      {props.showHeader && (
        <Grid row left xs={12} cssClass={Style.AutomationInformationHeader}>
          <Grid xs={5} md={10} cssClass={Style.title} center>
            <h1>Information</h1>
          </Grid>
          <Grid xs={7} md={2} cssClass={Style.controls} center>
            <div>
              <Button
                type='secondary'
                size='medium'
                label='Copy'
                icon='copy'
                cssClass={Style.shareButton}
                onClick={handleCopy}
              />
            </div>
            {automationDetails?.UploadTypeId === AutomationUploadTypes.UPLOAD_FILE ? (
              <div>
                <Button
                  type='default'
                  size='medium'
                  label='Download'
                  icon='file_download'
                  cssClass={Style.downloadButton}
                  onClick={() => handleDownload(automationDetails)}
                  disabled={!automationDetails?.AutomationFileUpload}
                />
              </div>
            ) : (
              <div>
                <Button
                  type='default'
                  size='medium'
                  label='View'
                  icon='open_in_new'
                  cssClass={Style.viewButton}
                  onClick={() => window.open(automationDetails?.AutomationUrl?.Url, '_blank')}
                  disabled={!props.isScriptLibrary && !automationDetails?.AutomationUrl?.Url}
                />
              </div>
            )}
          </Grid>
        </Grid>
      )}

      {!props.isScriptLibrary && automationDetails?.AutomationState?.Name === AutomationStateNames.IN_REVIEW && (
        <DetailRow title={'Status'}>
          {automationDetails && (
            <>
              <StatusIcon
                automation={automationDetails}
                appRole={AppRolesEnum.TECHNICAL}
                size='extra small'
                showApproverRole
              />
              <StatusIcon
                automation={automationDetails}
                appRole={AppRolesEnum.IT}
                size='extra small'
                showApproverRole
              />
              <StatusIcon
                automation={automationDetails}
                appRole={AppRolesEnum.DIGITAL}
                size='extra small'
                showApproverRole
              />
            </>
          )}
        </DetailRow>
      )}

      <DetailRow title={'Description'}>
        <span className={Style.automationDescription}>
          {descriptionParts.map((part) =>
            URL_MATCHER_REGEX.exec(part) ? (
              <a
                className={Style.automationDescription}
                key={indexKey()}
                href={part}
                target='_blank'
                rel='noopener noreferrer'
              >
                {part}
              </a>
            ) : (
              part
            ),
          )}
        </span>
      </DetailRow>

      <DetailRow title={'Source'}>
        {automationDetails?.AutomationSources?.map((source) => (
          <Tag id={source.Id.toString()} type={TagTypes.SOURCE} label={source.Name} key={source.Id} />
        ))}
      </DetailRow>

      <DetailRow title={'Capability'}>
        {automationDetails?.AutomationCapabilities
          ? automationDetails?.AutomationCapabilities?.map((capability) => (
              <Tag
                id={capability.Id.toString()}
                type={TagTypes.CAPABILITY}
                label={capability.Name}
                key={capability.Id}
              />
            ))
          : 'N/A'}
      </DetailRow>

      {!props.isScriptLibrary && (
        <>
          <DetailRow title={'Practice'}>
            {automationDetails?.AutomationPractices
              ? automationDetails?.AutomationPractices?.map((practice) => (
                  <Tag id={practice.Id.toString()} type={TagTypes.PRACTICE} label={practice.Name} key={practice.Id} />
                ))
              : 'N/A'}
          </DetailRow>
          <DetailRow title={'Region'}>
            {automationDetails?.AutomationRegions?.map((region) => (
              <span key={region.Id}>
                {region.Name}
                <span style={{ whiteSpace: 'nowrap' }}>, &nbsp;</span>
              </span>
            )) ?? 'N/A'}
          </DetailRow>
        </>
      )}

      <DetailRow title={'Primary Author'}>{automationDetails?.AutomationAuthor?.UserFullName}</DetailRow>

      <DetailRow title={'Originator'}>
        {(automationDetails?.AutomationOriginators || [])?.length > 0
          ? automationDetails?.AutomationOriginators?.map((originator, index) => (
              <span key={originator.UserId}>
                {originator.User.UserFullName}
                {index < (automationDetails.AutomationOriginators || []).length - 1 && (
                  <span style={{ whiteSpace: 'nowrap' }}>, &nbsp;</span>
                )}
              </span>
            ))
          : 'N/A'}
      </DetailRow>

      <DetailRow title={'Client'}>
        {(automationDetails?.AutomationClients || [])?.length > 0
          ? automationDetails?.AutomationClients?.map((client, index) => (
              <span key={client.ClientId}>
                {client.ClientName}
                {index < (automationDetails.AutomationClients || []).length - 1 && (
                  <span style={{ whiteSpace: 'nowrap' }}>, &nbsp;</span>
                )}
              </span>
            ))
          : 'N/A'}
      </DetailRow>

      {!props.isScriptLibrary && !props.isStreamliner && (
        <DetailRow title={'Verifiers'}>
          {automationVerifiers.length > 0
            ? automationVerifiers.map((approval, index) => {
                return (
                  <span key={indexKey()}>
                    {approval.ApprovedBy.UserFullName}
                    {index < automationVerifiers.length - 1 && <span style={{ whiteSpace: 'nowrap' }}>, &nbsp;</span>}
                  </span>
                )
              })
            : 'N/A'}
        </DetailRow>
      )}

      <DetailRow title={'Platform Interface'}>
        {(automationDetails?.AutomationPlatformInterfaces || []).length > 0
          ? automationDetails?.AutomationPlatformInterfaces?.map(
              (platformInterface, index) =>
                platformInterface && (
                  <span key={platformInterface.Id}>
                    {platformInterface.Name}
                    {index < (automationDetails.AutomationPlatformInterfaces || []).length - 1 && (
                      <span style={{ whiteSpace: 'nowrap' }}>, &nbsp;</span>
                    )}
                  </span>
                ),
            )
          : 'N/A'}
      </DetailRow>

      <DetailRow title={'Date of Creation'}>
        <span>
          {automationDetails?.DateCreated ? dateToString(new Date(String(automationDetails?.DateCreated))) : 'N/A'}
        </span>
      </DetailRow>

      {!props.isScriptLibrary && !props.isStreamliner && (
        <DetailRow title={'Verification Date'}>
          <span>
            {automationDetails?.VerificationDate
              ? dateToString(new Date(String(automationDetails?.VerificationDate)))
              : 'Awaiting Verification'}
          </span>
        </DetailRow>
      )}

      <DetailRow title={'Guidance Material Link'}>
        {automationDetails?.GuidanceMaterialUrl ? (
          <span>
            Discover our{' '}
            <a
              className={Style.guidanceMaterialUrl}
              href={automationDetails.GuidanceMaterialUrl}
              target='_blank'
              rel='noreferrer'
            >
              step-by-step guide
              <Icon size={'medium'} type={'file_copy'} outlined={true} cssClass={Style.guidanceMaterialIcon} />
            </a>
          </span>
        ) : (
          <span> Not Provided </span>
        )}
      </DetailRow>
    </>
  )
}

export default AutomationInformation
