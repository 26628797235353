import { getAsync } from '../helpers/apiRequest'
import { IResponse } from '../models/api/IResponse'
import { ISearchRequest } from '../models/api/ISearchRequest'
import { IWorkdayClientModel } from '../models/api/IWorkdayRequestModel'
import { TokenExpiryWrapper } from './TokenManager'

export const searchClients = TokenExpiryWrapper(
  (request: ISearchRequest): Promise<IResponse<IWorkdayClientModel[]>> => {
    return getAsync(`/v1/workday/client/search/${request.searchText}`, request)
  },
  [],
  null,
)
