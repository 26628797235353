import { selector } from 'recoil'
import { ResponseData } from '../models/api/IResponse'

import { getAllCapabilities } from '../api/CapabilityService'
import { ILookUp } from '../models/api/IAutomationRequest'

export const AllCapabilities = selector({
  key: 'all_capabilities_loader',
  get: async (): Promise<ILookUp[]> => {
    try {
      const results = await getAllCapabilities({})
      if (!results) return []
      const capabilities = ResponseData(results) as ILookUp[]
      return capabilities
    } catch (error) {
      console.log(error)
      return []
    }
  },
})
