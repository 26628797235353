import { FC } from 'react'
import Style from '../styles/Hero.module.sass'
import { Button, Grid } from '@aurecon-creative-technologies/styleguide'
import DefaultHeroImage from '../assets/recall_bamboo_cover.jpg'
import { IGetAutomationResponse } from '../models/api/IAutomationRequest'
import { AppRoutes } from '../enums/AppRoutes'
import { TOUR_LINK } from '../config/config'

interface HeroProps {
  automation?: IGetAutomationResponse
  firstName?: string
  coverPhoto?: string
}

const handleClick = (automationId: string) => {
  location.hash = `#/${AppRoutes.FEATURED}/${automationId}`
}

const handleTakeATourClick = () => {
  window.open(TOUR_LINK, '_blank')
}

const Hero: FC<HeroProps> = (props) => {
  return (
    <div className={Style.heroContainer}>
      <Grid row xl={2} cssClass={Style.welcomePanel}>
        <div className={Style.welcome}>Welcome</div>
        <div className={Style.user}>{props.firstName}</div>
        <div className={Style.message}>
          Automation Centre is our centralised hub to share, search and run automation solutions.
        </div>
        <Button label='Take a Tour' cssClass={Style.takeATour} type='text' onClick={handleTakeATourClick} />
      </Grid>
      <Grid
        xl={10}
        style={{ backgroundImage: `url(${props.coverPhoto ?? DefaultHeroImage})` }}
        cssClass={Style.heroImage}
      >
        <div onClick={() => handleClick(props.automation?.Id ?? '')} className={Style.featuredDetails} role='none'>
          <div className={Style.featuredTitle}>{props.automation?.Title}</div>
          <div className={Style.featuredDescription}>{props.automation?.Description}</div>
        </div>
      </Grid>
    </div>
  )
}

export default Hero
