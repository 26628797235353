import { FC } from 'react'

import Style from '../styles/MaintenanceScreen.module.sass'
import { Container } from '@aurecon-creative-technologies/styleguide'
import AureconLogo from '../assets/aurecon_logo.png'
import AureconGrey from '../assets/aurecon_logo_grey.svg'
import AutomationCentreLogo from '../assets/automation_center_logo.svg'

interface IMaintenanceScreenProps {
  customMessage?: string
}

const MaintenanceScreen: FC<IMaintenanceScreenProps> = (props) => {
  return (
    <Container fluid cssClass={Style.container}>
      <div className={Style.aureconLogo}>
        <img src={AureconLogo} alt='Aurecon Logo' />
      </div>
      <div className={Style.contents}>
        <img src={AutomationCentreLogo} alt='Automation Centre Logo' />
        <h1 className={Style.line1}>We will be back in a bit</h1>
        <h1 className={Style.line2}>System maintenance is in progress</h1>
        <p className={Style.customMessage}>{props.customMessage}</p>
        <p className={Style.line3}>For further information, please contact the web administrator</p>
      </div>
      <img src={AureconGrey} alt='aurecon' className={Style.logoBackground} />
    </Container>
  )
}

export default MaintenanceScreen
