import { FC, useEffect, useState } from 'react'
import { Button, Container } from '@aurecon-creative-technologies/styleguide'
import Page from '../components/Page'
import LoadingScreen from '../components/LoadingScreen'
import { appInsights } from '../api/AppInsights'

import Style from '../styles/Login.module.sass'
import { TokenManager } from '../api/TokenManager'

interface IConsentProps {
  setConsented: () => void
}

/* Page which allows user to initiate consent process */
const Consent: FC<IConsentProps> = ({ setConsented }) => {
  const [checkingConsent, setCheckingConsent] = useState(true)

  if (appInsights) appInsights.trackPageView({ name: 'Consent' })

  async function consentProcess() {
    try {
      const tokenMgr = await TokenManager()
      await tokenMgr.initiateConsentPopup()
      setConsented()
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    async function runEffect() {
      setCheckingConsent(false)
      setConsented()
    }
    runEffect()
  })

  return (
    <Page>
      {checkingConsent ? (
        <LoadingScreen text='Checking access...' />
      ) : (
        <Container cssClass={Style.consentRequired}>
          <h1>Consent is required</h1>
          <Button label='Access consent' onClick={consentProcess} />
        </Container>
      )}
    </Page>
  )
}

export default Consent
