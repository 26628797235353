import { Grid } from '@aurecon-creative-technologies/styleguide'
import { FC } from 'react'
import Style from '../../styles/AutomationViewer.module.sass'
import DetailRow from './DetailRow'
import { IGetAutomationResponse } from '../../models/api/IAutomationRequest'

interface EstimatedAverageTimeSavedProps {
  automationDetails?: IGetAutomationResponse
  isScriptLibrary?: boolean
  isStreamliner?: boolean
}

const EstimatedAverageTimeSaved: FC<EstimatedAverageTimeSavedProps> = (props: EstimatedAverageTimeSavedProps) => {
  if (props.isScriptLibrary || props.isStreamliner) return null

  const automationDetails = props.automationDetails
  const estimatedTimeSaved =
    Number(automationDetails?.CurrentActivityTime) - Number(automationDetails?.AutomatedActivityTime)
  return (
    <>
      <div className={Style.divider} />
      <Grid row left xs={12} cssClass={Style.sectionHeader}>
        <h2>Estimated Average Time Saved Per Run</h2>
      </Grid>
      <DetailRow title={'Current Activity Time'}>
        {automationDetails?.CurrentActivityTime ? (
          <span> {automationDetails?.CurrentActivityTime} minutes</span>
        ) : (
          <span>N/A</span>
        )}
      </DetailRow>
      <DetailRow title={'Automated Activity Time'}>
        {automationDetails?.AutomatedActivityTime ? (
          <span> {automationDetails?.AutomatedActivityTime} minutes</span>
        ) : (
          <span>N/A</span>
        )}
      </DetailRow>
      <DetailRow title={'Estimated Time Saving Per Run'}>
        {automationDetails?.AutomatedActivityTime || automationDetails?.CurrentActivityTime ? (
          <span> {estimatedTimeSaved} minutes</span>
        ) : (
          <span>N/A</span>
        )}
      </DetailRow>
    </>
  )
}

export default EstimatedAverageTimeSaved
