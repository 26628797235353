import Joi from 'joi'

export const commentFormFields = {
  comment: 'comment',
}

export const commentFormSchema = Joi.object({
  comment: Joi.string().trim().max(1000).messages({
    'string.empty': 'This is a required field',
    'string.max': 'Comment must be 1000 characters or less',
  }),
})
