import { IKeyValue } from '../models/IKeyValue'

//Should match table LUAutomationSource
export const AutomationSources: IKeyValue[] = [
  { key: '8', value: 'Microsoft VBA Code (Macro)' },
  { key: '13', value: 'Microsoft Excel' },
  { key: '12', value: 'Office Scripts' },
  { key: '9', value: 'Power Automate' },
  { key: '10', value: 'Power Apps' },
  { key: '11', value: 'Other' },
  { key: '1', value: 'Python' },
  { key: '2', value: 'Dynamo' },
  { key: '3', value: 'Rhino' },
  { key: '4', value: 'Grasshopper' },
  { key: '5', value: 'Cpython' },
  { key: '6', value: 'Csharp' },
  { key: '7', value: 'iFC' },
  { key: '14', value: 'Streamliner' },
]

export enum AutomationSourcesID {
  PYTHON = 1,
  DYNAMO = 2,
  RHINO = 3,
  GRASSHOPPER = 4,
  CPYTHON = 5,
  CSHARP = 6,
  IFC = 7,
  MACRO = 8,
  POWER_AUTOMATE = 9,
  POWER_APPS = 10,
  OTHER = 11,
  OFFICE_SCRIPTS = 12,
  EXCEL = 13,
  STREAMLINER = 14,
}
