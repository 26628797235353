export const excelMacroTypes: string[] = ['.xla', '.xlt', '.xlam', '.xlsb', '.xlsm', '.xltm']
export const powerpointMacroTypes: string[] = ['.pot', '.ppa', '.pps', '.ppt', '.potm', '.ppam', '.ppsm', '.pptm']
export const wordMacroTypes: string[] = ['.doc', '.dot', '.wiz', '.docm', '.dotm']
export const projectMacroTypes: string[] = ['.mpp', '.mpt']
export const publisherMacroTypes: string[] = ['.pub']
export const visioMacroTypes: string[] = [
  '.vdw',
  '.vdx',
  '.vsd',
  '.vss',
  '.vst',
  '.vsx',
  '.vtx',
  '.vsdm',
  '.vssm',
  '.vstm',
]
export const officeScriptTypes: string[] = ['.osts']

export const microsoftExcelTypes: string[] = ['.xlsx', '.xls']

export const dropZoneAcceptMacroFileTypes: string[] = [
  ...excelMacroTypes,
  ...powerpointMacroTypes,
  ...wordMacroTypes,
  ...projectMacroTypes,
  ...publisherMacroTypes,
  ...visioMacroTypes,
]

export const getExtensionFromFilename = (filename?: string): string => {
  if (!filename?.length || filename.lastIndexOf('.') < 1) return '.unknown'
  return filename.substring(filename.lastIndexOf('.')).toLowerCase()
}
