import React, { FC, useEffect } from 'react'
import classNames from 'classnames'
import { Button, Container, Grid } from '@aurecon-creative-technologies/styleguide'
import Menu from './Menu'
import Style from '../styles/Page.module.sass'
import Footer from './Footer'
import { useShowFeature } from '../hooks/useShowFeature'
import { FeatureFlagEnum } from '../enums/FeatureFlagEnum'
import MaintenanceScreen from './MaintenanceScreen'

export interface IPageProps {
  menu?: boolean
  footer?: boolean
  children?: JSX.Element[] | JSX.Element | React.ReactNode
  contentWrapper?: boolean
  handleGoBack?: () => void
  contentsRef?: React.RefObject<HTMLDivElement>
  manage?: boolean
}

const Page: FC<IPageProps> = (props) => {
  const showMaintenanceMode = useShowFeature(FeatureFlagEnum.ShowMaintenanceMode)
  const message = showMaintenanceMode.client_filters?.find((cf) => cf.name === 'Message')?.parameters?.Banner

  useEffect(() => {
    if (!props.contentsRef?.current) {
      return
    }

    props.contentsRef.current.scrollTo({ top: props.contentsRef.current.scrollHeight, behavior: 'smooth' })
  }, [props.contentsRef])

  const pageContentsClasses = classNames({
    [Style.pageContents]: true,
  })

  const containerClasses = classNames({
    [Style.container]: true,
    [Style.manage]: props.manage,
  })

  return (
    <div className={Style.page}>
      {showMaintenanceMode.enabled ? (
        <MaintenanceScreen customMessage={message} />
      ) : (
        <Container cssClass={containerClasses}>
          <div className={Style.columns}>
            {props.menu && <Menu />}
            <div className={Style.mainPageWrapper} ref={props.contentsRef}>
              {props.contentWrapper ? (
                <Container cssClass={pageContentsClasses} fluid>
                  {props.handleGoBack && (
                    <Grid row left xs={12}>
                      <Button
                        type='icon-round-secondary'
                        size='small'
                        icon='chevron_left'
                        onClick={props.handleGoBack}
                        cssClass={Style.backButton}
                      />
                    </Grid>
                  )}
                  {props.children}
                </Container>
              ) : (
                <>{props.children}</>
              )}
            </div>
          </div>
        </Container>
      )}
      {props.footer && <Footer />}
    </div>
  )
}

export default Page
