import { FC, useState } from 'react'
import Page from '../components/Page'
import { Button, Grid, Icon, Pill, Text, useToast } from '@aurecon-creative-technologies/styleguide'
import EstimatedAverageTimeSaved from '../components/AutomationViewer/EstimatedAverageTimeSaved'
import AutomationInformation from '../components/AutomationViewer/AutomationInformation'
import DefaultCoverPhoto from '../assets/cover_default.png'
import HeroFeaturedCoverPhoto from '../assets/recall_bamboo_cover.jpg'
import Tag, { ITagProps } from '../components/Tag'
import { dateToString, dateTimeFormat, indexKey } from '../helpers/utils'
import { useParams } from 'react-router-dom'
import { FeaturedAutomations, HeroAutomation, featuredText } from '../enums/TemporaryFeatured'
import { IGetAutomationResponse, ILookUp } from '../models/api/IAutomationRequest'
import { AutomationUploadTypes } from '../enums/AutomationUploadTypes'
import { handleDownload, markdownLinkify } from '../helpers/commonFunctions'
import { TagTypes } from '../enums/TagTypes'
import Likes from '../components/Likes'
import Style from '../styles/FeaturedViewer.module.sass'

interface IFeaturedViewerProps {
  maxTags: number
}

const FeaturedViewer: FC<IFeaturedViewerProps> = (props) => {
  const { addToast } = useToast()
  const { automationId } = useParams<{ automationId: string }>()
  const [showCompleteTags, setShowCompleteTags] = useState<boolean>(false)

  const { maxTags } = props

  const now = new Date()
  const showToast = () => {
    addToast({
      type: 'success',
      message: 'Link Copied To Clipboard',
      timeout: 2000,
      datetime: Number(dateToString(now, dateTimeFormat)),
    })
  }

  const automation =
    automationId === HeroAutomation.Id
      ? HeroAutomation
      : FeaturedAutomations.find((automation) => automation.Id === automationId)

  const automationText = featuredText.find((text) => text.id === automationId)

  const handleCopy = async () => {
    const currentUrl = window.location.href
    await navigator.clipboard.writeText(currentUrl)
    showToast()
  }

  const renderTags = () => {
    const completeTagItems = []
    let limitedTagItems: ITagProps[] = []
    if (automation?.AutomationCapabilities)
      completeTagItems.push(...mapPillItems(automation?.AutomationCapabilities, TagTypes.CAPABILITY))
    if (automation?.AutomationSources)
      completeTagItems.push(...mapPillItems(automation?.AutomationSources, TagTypes.SOURCE))
    if (automation?.AutomationPractices)
      completeTagItems.push(...mapPillItems(automation?.AutomationPractices, TagTypes.PRACTICE))

    if (completeTagItems.length > maxTags) limitedTagItems = completeTagItems.splice(0, maxTags)

    const displayTags = !showCompleteTags && completeTagItems.length > maxTags ? limitedTagItems : completeTagItems

    const moreTags = completeTagItems.length - maxTags

    return (
      <div className={Style.tags}>
        {displayTags.map((item) => (
          <Tag id={item.id} type={item.type} size='medium' label={String(item.label)} key={indexKey()} />
        ))}
        {completeTagItems.length > maxTags && !showCompleteTags && (
          <Pill size='medium' cssClass={Style.displayPill} onClick={() => setShowCompleteTags(true)} icon={'add'}>
            Show {moreTags} more
          </Pill>
        )}
        {completeTagItems.length > maxTags && showCompleteTags && (
          <Pill size='medium' cssClass={Style.displayPill} onClick={() => setShowCompleteTags(false)} icon={'remove'}>
            Show less
          </Pill>
        )}
      </div>
    )
  }

  const mapPillItems = (data: ILookUp[], type: TagTypes.CAPABILITY | TagTypes.SOURCE | TagTypes.PRACTICE) => {
    const result: ITagProps[] = []
    data.forEach((item) => {
      result.push({
        id: item.Id.toString(),
        label: item.Name,
        type,
      })
    })

    return result
  }

  return (
    <Grid row xs={12}>
      <Grid
        xs={12}
        sm={12}
        md={4}
        cssClass={Style.cover}
        style={{
          backgroundImage: `url(${automationId === HeroAutomation.Id ? HeroFeaturedCoverPhoto : DefaultCoverPhoto})`,
        }}
      >
        <Button
          type='icon-round-secondary'
          size='small'
          icon='chevron_left'
          onClick={() => history.back()}
          cssClass={Style.backButton}
        />

        <div className={Style.titleContainer}>
          <div className={Style.upperSection}>
            <div className={Style.title}>{automation?.Title}</div>
            {automation?.UploadTypeId === AutomationUploadTypes.UPLOAD_FILE ? (
              <div className={Style.downloadBtn} onClick={() => handleDownload(automation)} role='none'>
                <Icon type='file_download' outlined />
              </div>
            ) : (
              <div className={Style.downloadBtn}>
                <Icon
                  type='open_in_new'
                  outlined
                  onClick={() => window.open(automation?.AutomationUrl?.Url, '_blank')}
                />
              </div>
            )}
          </div>
          {renderTags()}
        </div>
      </Grid>
      <Grid xs={12} sm={12} md={8} cssClass={Style.details}>
        <Page contentWrapper>
          <div className={Style.copyContainer}>
            <Likes size='24px' id={automationId ?? ''} defaultUp={false} />
            <Icon type='copy' outlined onClick={handleCopy} cssClass={Style.copyIcon} />
          </div>

          {automationText && (
            <>
              <div className={Style.divider} />
              {automationText?.section1.map((text: string) => (
                <Text type='p' key={indexKey()}>
                  {markdownLinkify(text)}
                </Text>
              ))}

              <img src={automationText?.image} alt='featured automation' style={{ width: '100%' }} />

              {automationText?.section2?.map((text) => (
                <Text type='p' key={indexKey()}>
                  {text}
                </Text>
              ))}
            </>
          )}

          <AutomationInformation showHeader showDivider automationDetails={automation as IGetAutomationResponse} />
          <EstimatedAverageTimeSaved automationDetails={automation as IGetAutomationResponse} />
        </Page>
      </Grid>
    </Grid>
  )
}

export default FeaturedViewer
