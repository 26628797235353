import { IButtonProps, Modal } from '@aurecon-creative-technologies/styleguide'
import { FC } from 'react'
import { useRecoilState } from 'recoil'
import { ShowApproverModal } from '../../stores/AutomationViewerStore'
import Style from '../../styles/AutomationViewer.module.sass'
import { ApproverModalActionTypes, ApproverModalContent, ApproverModalTitle } from '../../enums/AutomationViewer'

interface ApproverModalProps {
  onYes: () => void
}

const ApproverModal: FC<ApproverModalProps> = ({ onYes }) => {
  const [showApproverModal, setShowApproverModal] = useRecoilState(ShowApproverModal)

  const handleClose = () => {
    setShowApproverModal({ show: false, type: ApproverModalActionTypes.CLOSE })
  }

  const handleConfirm = () => {
    onYes()
  }

  const ModalActions: IButtonProps[] = [
    {
      label: 'Yes',
      type: 'secondary',
      onClick: handleConfirm,
      cssClass: Style.actionConfirm,
    },
    {
      label: 'No',
      onClick: handleClose,
      cssClass: Style.actionDeny,
    },
  ]

  return (
    <Modal
      isShowing={showApproverModal.show}
      onClose={handleClose}
      actions={ModalActions}
      shouldOverlayClose={false}
      isCloseButton={true}
      size='small'
      cssClass={Style.approverModal}
      modalPadding={{
        top: '60px',
        right: '24px',
        left: '24px',
      }}
    >
      <>
        <h1 className={Style.modalTitle}>{ApproverModalTitle[showApproverModal.type]}</h1>
        <p className={Style.modalContent}>{ApproverModalContent[showApproverModal.type]}</p>
      </>
    </Modal>
  )
}

export default ApproverModal
