import { FC, useEffect, useState } from 'react'
import Page from '../components/Page'
import Style from '../styles/AutomationViewer.module.sass'
import { Button, Grid, Icon, useToast } from '@aurecon-creative-technologies/styleguide'
import EstimatedAverageTimeSaved from '../components/AutomationViewer/EstimatedAverageTimeSaved'
import AutomationInformation from '../components/AutomationViewer/AutomationInformation'
import VerificationInformation from '../components/AutomationViewer/VerificationInformation'
import CommentForm from '../components/AutomationViewer/CommentForm'
import { useNavigate, useParams } from 'react-router-dom'
import { dateTimeFormat, dateToString } from '../helpers/utils'
import ErrorModal from '../components/ErrorModal'
import { useRecoilValueLoadable } from 'recoil'
import { AutomationDetails } from '../stores/AutomationViewerStore'
import { AutomationUploadTypes } from '../enums/AutomationUploadTypes'
import { IGetAutomationResponse } from '../models/api/IAutomationRequest'
import LoadingScreen from '../components/LoadingScreen'
import NotFound from './NotFound'
import { useAuth0 } from '@auth0/auth0-react'
import { AutomationStateNames } from '../enums/AutomationStates'
import { AppRoutes } from '../enums/AppRoutes'
import { handleDownload } from '../helpers/commonFunctions'
import { SCRIPT_LIBRARY_VIEW_URL } from '../config/config'
import { AutomationApprovalDisplayStates } from '../enums/AutomationApprovalStates'
import Likes from '../components/Likes'
import LoadingModal from '../components/LoadingModal'

const AutomationViewer: FC = () => {
  const [automation, setAutomation] = useState<IGetAutomationResponse | null>()
  const { automationId, typeId } = useParams<{ automationId: string; typeId: string }>()
  const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false)
  const [loadingModalMessage, setLoadingModalMessage] = useState<string>('Loading...')
  const navigate = useNavigate()
  const { addToast } = useToast()
  const { user } = useAuth0()

  const automationDetails = useRecoilValueLoadable(
    AutomationDetails({ id: String(automationId), typeId: Number(typeId) }),
  )

  const hasActionRequired = Boolean(
    automation?.AutomationApprovals?.filter(
      (approval) => approval.ApprovalState.Name === AutomationApprovalDisplayStates.ActionRequired,
    ).length,
  )
  const isScriptLibrary = Number(typeId) === AutomationUploadTypes.SCRIPT_LIBRARY
  const isStreamLiner = Number(typeId) === AutomationUploadTypes.STREAMLINER
  const isInReview = automation?.AutomationState?.Name === AutomationStateNames.IN_REVIEW
  const isOwner = automation?.AutomationAuthor?.UserEmail === user?.email
  const isApprover = automation?.CanApprove ?? false
  const hasReviews = Boolean(automation?.AutomationApprovals?.length)

  const showEditButton = isOwner && hasActionRequired
  const showAuthorButtons = !isScriptLibrary || !isInReview
  const showVerificationInformation = (isOwner || isApprover) && hasReviews

  useEffect(() => {
    if (!automationDetails || automationDetails.state !== 'hasValue' || !automationDetails.contents) {
      return
    }
    setAutomation(automationDetails.contents)
  }, [automationDetails])

  if (automationDetails.state === 'loading') {
    return (
      <Page>
        <LoadingScreen text='Loading Details' />
      </Page>
    )
  }

  const now = new Date()
  const showToast = () => {
    addToast({
      type: 'success',
      message: 'Link Copied To Clipboard',
      timeout: 2000,
      datetime: Number(dateToString(now, dateTimeFormat)),
    })
  }

  const handleCopyClick = async () => {
    const currentUrl = window.location.href
    await navigator.clipboard.writeText(currentUrl)
    showToast()
  }

  if (automationDetails.state === 'hasValue' && !automationDetails.contents) {
    return <NotFound />
  }

  const handleViewClick = () => {
    if (isScriptLibrary) {
      window.open(`${SCRIPT_LIBRARY_VIEW_URL}/${automation?.Id}`, '_blank')
      return
    }

    window.open(automation?.AutomationUrl?.Url, '_blank')
  }

  const handleEditClick = () => {
    navigate(`/${AppRoutes.UPLOAD_AUTOMATION}/${automationId}`)
  }

  const handleGoBack = () => {
    if (history.length <= 2) {
      navigate(`/${AppRoutes.MANAGE}`)
      return
    }
    navigate(-1)
  }

  const handleDownloadClick = async () => {
    if (!automation) return
    setLoadingModalMessage('Loading file...')
    setShowLoadingModal(true)
    await handleDownload(automation)
    setShowLoadingModal(false)
  }

  return (
    <Page menu contentWrapper handleGoBack={handleGoBack}>
      {!automation ? (
        <div className={Style.viewerLoader}>
          <LoadingScreen size='extra small' text='Loading Details' />
        </div>
      ) : (
        <>
          <Grid row left xs={12}>
            <Grid xs={12} md={10} cssClass={Style.headerTitle} center>
              <h1>{automation?.Title}</h1>
            </Grid>

            <Grid xs={12} md={2} cssClass={Style.controls} center>
              <div>
                <Likes id={automation.Id} size='24px' />
              </div>
              {!isScriptLibrary && (
                <div>
                  <Icon type='copy' outlined onClick={handleCopyClick} cssClass={Style.copyIcon} />
                </div>
              )}
              <div>
                <Button
                  type='default'
                  size='medium'
                  label='View'
                  icon='open_in_new'
                  cssClass={Style.viewButton}
                  onClick={handleViewClick}
                  disabled={!isScriptLibrary && !automation?.AutomationUrl?.Url}
                />
              </div>
              <div>
                <Button
                  type='default'
                  size='medium'
                  label='Download'
                  icon='file_download'
                  cssClass={Style.downloadButton}
                  onClick={handleDownloadClick}
                  disabled={!automation.AutomationFileUpload}
                />
              </div>
            </Grid>
          </Grid>
          {automation && (
            <>
              <div className={Style.verificationInformationHeader}>Information</div>
              <AutomationInformation
                automationDetails={automation}
                isScriptLibrary={isScriptLibrary}
                isStreamliner={isStreamLiner}
              />
              <EstimatedAverageTimeSaved
                automationDetails={automation}
                isScriptLibrary={isScriptLibrary}
                isStreamliner={isStreamLiner}
              />
            </>
          )}

          {showVerificationInformation && (
            <>
              <div className={Style.divider} />
              <VerificationInformation automationApprovals={automation?.AutomationApprovals} />
            </>
          )}

          {!isOwner &&
            isApprover &&
            !isScriptLibrary &&
            automation?.AutomationState?.Name !== AutomationStateNames.APPROVED && (
              <CommentForm automation={automation} user={user} />
            )}

          {showAuthorButtons && (
            <>
              <div className={Style.divider} />
              <div className={Style.authorButtons}>
                {showEditButton && (
                  <Button
                    type='default'
                    size='medium'
                    label='Edit'
                    disabled={!hasActionRequired && isInReview}
                    cssClass={Style.edit}
                    onClick={handleEditClick}
                  />
                )}
                <Button type='default' size='medium' label='Close' cssClass={Style.close} onClick={handleGoBack} />
              </div>
            </>
          )}
          <ErrorModal />
        </>
      )}
      {showLoadingModal && <LoadingModal message={loadingModalMessage} />}
    </Page>
  )
}

export default AutomationViewer
