import { IButtonProps, Modal } from '@aurecon-creative-technologies/styleguide'
import { FC } from 'react'
import { useRecoilState } from 'recoil'
import { ShowUploadWarningModal } from '../../stores/AutomationUploadStore'
import Style from '../../styles/CommonModals.module.sass'

interface WarningModalProps {
  onYes?: () => void
  onNo?: () => void
}

const WarningModal: FC<WarningModalProps> = ({ onYes, onNo }) => {
  const [showUploadWarningModal, setShowUploadWarningModal] = useRecoilState(ShowUploadWarningModal)

  const handleClose = () => {
    setShowUploadWarningModal(false)
  }

  const handleYes = () => {
    handleClose()
    onYes?.()
  }

  const handleNo = () => {
    handleClose()
    onNo?.()
  }

  const ModalActions: IButtonProps[] = [
    {
      label: 'Cancel',
      onClick: handleNo,
      cssClass: Style.secondaryButton,
    },
    {
      label: 'Change',
      onClick: handleYes,
      cssClass: Style.primaryButton,
    },
  ]

  return (
    <Modal
      isShowing={showUploadWarningModal}
      isCloseButton
      onClose={handleClose}
      actions={ModalActions}
      shouldOverlayClose={false}
      size='medium'
      cssClass={Style.commonModal}
      modalPadding={{
        top: '24px',
        right: '24px',
        left: '24px',
      }}
    >
      <>
        <div className={Style.headerRow}>Warning</div>
        <p className={Style.modalContent}>
          Before changing the source, please keep in mind that all data will be cleared. Are you sure you want to
          proceed?
        </p>
      </>
    </Modal>
  )
}

export default WarningModal
