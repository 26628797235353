import { FC } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@aurecon-creative-technologies/styleguide'
import { ReactComponent as AureconLogo } from '../assets/aurecon_logo_white.svg'
import { ABOUT_URL, SERVICE_NOW_URL, SUPPORT_HUB_URL } from '../config/config'
import { appInsights } from '../api/AppInsights'

import Style from '../styles/Login.module.sass'
import { Navigate, useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { AppVersion } from '../stores/AppStore'

const Login: FC = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0()
  const version = useRecoilValue(AppVersion)
  const location = useLocation()
  if (appInsights) appInsights.trackPageView({ name: 'Login' })

  if (isAuthenticated) return <Navigate to='/' />

  const handleButtonClick = (url: string) => {
    window.open(url)
  }

  interface IState {
    from: { pathname: string }
  }

  const handleLogin = () => {
    const state = location.state as IState
    const targetUrl = state ? `/#${state.from.pathname}` : '/'

    loginWithRedirect({
      appState: { targetUrl },
      connection_scope: `offline_access`,
    })
  }

  return (
    <div className={`${Style.loginPage} login`}>
      <div className={Style.loginContainer}>
        <div className={Style.header}>
          <AureconLogo height={30} />
        </div>
        <div className={Style.content}>
          <h2 className={Style.welcome}>Welcome to</h2>
          <h1 className={Style.appName}>Automation Centre</h1>
          <div className={Style.paragraph}>
            <p>
              Your centralised hub for locating automation tools, scripts and solutions across our enterprise and
              capabilities.
            </p>
          </div>
          <Button type='primary' cssClass='is-white' label='Login' onClick={handleLogin} />
        </div>

        <div className={Style.footer}>
          <div className={Style.links}>
            <Button type='text' cssClass='is-white' label='About' onClick={() => handleButtonClick(ABOUT_URL)} />
            <Button
              type='text'
              cssClass='is-white'
              label='Login Help'
              onClick={() => handleButtonClick(SERVICE_NOW_URL)}
            />
            <Button
              type='text'
              cssClass='is-white'
              label='Contact Us'
              onClick={() => handleButtonClick(SUPPORT_HUB_URL)}
            />
          </div>
          <div className={Style.version}>Version: {version}</div>
        </div>
      </div>
    </div>
  )
}

export default Login
