import { IButtonProps, Icon, Modal } from '@aurecon-creative-technologies/styleguide'
import { FC, useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { ShowActionNotRequiredModal } from '../../stores/AutomationViewerStore'
import Style from '../../styles/AutomationViewer.module.sass'

interface IActionNotRequiredModal {
  show: boolean
}

const ActionNotRequiredModal: FC<IActionNotRequiredModal> = (props) => {
  const [showActionNotRequired, setShowActionNotRequired] = useRecoilState(ShowActionNotRequiredModal)

  useEffect(() => {
    setShowActionNotRequired({ show: props.show })
  }, [props.show, setShowActionNotRequired])

  const handleClose = () => {
    setShowActionNotRequired({ show: false })
  }

  const ModalActions: IButtonProps[] = [
    {
      label: 'Close',
      onClick: handleClose,
      cssClass: Style.actionDeny,
    },
  ]

  return (
    <Modal
      isShowing={showActionNotRequired.show}
      onClose={handleClose}
      actions={ModalActions}
      shouldOverlayClose={false}
      isCloseButton={true}
      size='small'
      cssClass={Style.actionNotRequiredModal}
      modalPadding={{
        top: '30px',
        right: '24px',
        left: '24px',
      }}
    >
      <>
        <div className={Style.iconRow}>
          <Icon type='error_outlined' cssClass={Style.icon} />
          <h2>Note</h2>
        </div>
        <p className={Style.modalContent}>
          This Automation has been rejected by another approver. Approval action is no longer required.
        </p>
      </>
    </Modal>
  )
}

export default ActionNotRequiredModal
